export const ADD_RUTAS_CLIENTE = 'roles/add-rutas-cliente';
export const CLEAN_FORM = 'roles/clean-form';
export const DROP_RUTA_CLIENTE = 'roles/drop-ruta-cliente';
export const ERROR_RUTAS_LIST = 'roles/error-rutas-list';
export const RECEIVE_RUTAS_LIST = 'roles/receive-rutas-list';
export const REQUEST_RUTAS_LIST = 'roles/request-rutas-list';
export const SET_ENTITY = 'roles/set-entity';
export const SET_ERROR = 'roles/set-error';
export const SET_MASK = 'roles/set-mask';
export const SET_ORDER = 'roles/set-order';
export const SET_PAGE = 'roles/set-page';
export const SET_PAGE_SIZE = 'roles/set-page-size';
export const SET_SELECTED_ROW = 'roles/set-selected-row';
export const SET_STATE_FORM = 'roles/set-state-form';
export const UPDATE_FIELD_ENTITY = 'roles/update-field-entity';
export const UPDATE_FIELD_FILTER = 'roles/update-field-filter';
export const UPDATE_RUTA_CLIENTE = 'roles/update-ruta-cliente';
export const UPDATE_TABLE_COLUMN = 'roles/update-table-column';