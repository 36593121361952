import React from 'react';
import { useDispatch, useSelector,} from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateColumn,
    //btnSearch,
    setOrder,
   // setPage,
    //setPageSize,
    setSelectedRow,
    searchSub
} from '../../../store/clientes/prospectos/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ProspectosList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector(state => state.aprobarprospectos.table.columns);
    const loading = useSelector(state => state.aprobarprospectos.loading);
    const order = useSelector(state => state.aprobarprospectos.table.order);
    const orderBy = useSelector(state => state.aprobarprospectos.table.orderBy);
    const store = useSelector(state => state.aprobarprospectos.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (id)=>  {
        dispatch(saveDetail(id));
            dispatch(searchSub(id.ProspectoId))
        history.push({
            hash: location.hash,
            pathname: `/clientes/prospectos/${id}`,
            search: location.search
        }); 
    }

    const saveDetail = (id)=> (dispatch, getState) => {
       getState().aprobarprospectos.store.detalle = id
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
     };

    // function handleChangePage(newPage) {
    //     dispatch(setPage(newPage));
    //     dispatch(btnSearch());
    // }
    
    // function handleChangeRowsPerPage(event) {
    //     dispatch(setPageSize(event.target.value));
    //     dispatch(setPage(0));
    // }

  
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    //onChangePage={handleChangePage}
                    //onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={item => handleDoubleClick(item)} //item.ProspectoId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default ProspectosList;