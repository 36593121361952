import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onUpdateFieldEntity } from "../../../store/fuerzaventas/gestionbonificacion/actions";

import { FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0,
    },
    details: {
        display: "flex",
        padding: "0.5rem 0.75rem 0.75rem",
    },
    label: {
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.54)",
        display: "flex",
        fontSize: "0.8125rem",
        width: 90,
    },
    paper: {
        marginBottom: "0.75rem",
        "@media (min-width: 1401px)": {
            marginBottom: "1rem",
        },
    },
}));

const GestionBonificacionForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:701px)");
    const entity = useSelector((state) => state.gestionbonificacion.entity);
    const errors = useSelector((state) => state.gestionbonificacion.errors);
    const formState = useSelector(
        (state) => state.gestionbonificacion.formState
    );
    const dispatch = useDispatch();
    const isView = formState === "view";

    function onFieldChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }
    return (
        <div>
            <FieldSet
                className={classes.paper}
                iconCls="fas fa-address-card"
                title="Bonificacion"
            >
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        {entity.bonificacionId !== "" && (
                            <Grid item xs={12} container>
                                <Grid item xs={matches ? 3 : 12}>
                                    <TextField
                                        label="Identificador"
                                        value={entity.bonificacionId}
                                        variant={
                                            matches ? "outlined" : "standard"
                                        }
                                        fullWidth
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {/* <Grid item xs={12} container>
                            <Grid item xs={matches ? 2 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nit !== ""}
                                    label="NIT"
                                    name="nit"
                                    onChange={onFieldChange}
                                    value={entity.nit}
                                    helperText={errors.nit}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid> */}

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 2 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.codigoSucursal !== ""}
                                    label="Codigo Sucursal"
                                    name="codigoSucursal"
                                    onChange={onFieldChange}
                                    value={entity.codigoSucursal}
                                    helperText={errors.codigoSucursal}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.producto !== ""}
                                    label="Producto"
                                    name="producto"
                                    onChange={onFieldChange}
                                    value={entity.producto}
                                    helperText={errors.producto}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.bonificacion !== ""}
                                    label="Bonificacion"
                                    name="bonificacion"
                                    onChange={onFieldChange}
                                    value={entity.bonificacion}
                                    helperText={errors.bonificacion}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 2 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.tipo !== ""}
                                    label="Tipo Bonificacion"
                                    name="tipo"
                                    onChange={onFieldChange}
                                    value={entity.tipo}
                                    helperText={errors.tipo}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 2 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.desde !== ""}
                                    label="Fecha de Inicio"
                                    name="desde"
                                    onChange={onFieldChange}
                                    value={entity.desde}
                                    helperText={errors.desde}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 2 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.hasta !== ""}
                                    label="Fecha de Vencimiento"
                                    name="hasta"
                                    onChange={onFieldChange}
                                    value={entity.hasta}
                                    helperText={errors.hasta}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly:
                                            isView || formState === "restart",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </FieldSet>
        </div>
    );
};

export default GestionBonificacionForm;
