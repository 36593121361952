export const CLEAN_FORM = "fuerzaventas/gestionbonificacion/clean-form";
export const ADVANCED_SEARCH_CLOSE = "main/advanced-search-close";
export const ADVANCED_SEARCH_OPEN = "main/advanced-search-open";
export const SET_STATE_FORM = "fuerzaventas/gestionbonificacion/set-state-form";
export const UPDATE_FIELD_FILTER =
    "fuerzaventas/gestionbonificacion/update-field-filter";
export const RECEIVE_V_LIST = "fuerzaventas/gestionbonificacion/receive-v-list";
export const RECEIVE_V_LIST2 =
    "fuerzaventas/gestionbonificacion/receive-v-list2";
export const REQUEST_V_LIST = "fuerzaventas/gestionbonificacion/request-v-list";
export const ERROR_V_LIST = "fuerzaventas/gestionbonificacion/error-v-list";
export const SET_MASK = "fuerzaventas/gestionbonificacion/set-mask";
export const SET_ORDER = "fuerzaventas/gestionbonificacion/set-order";
export const RECEIVE_DATA = "fuerzaventas/gestionbonificacion/receibe-data";
export const UPDATE_TABLE_COLUMN =
    "fuerzaventas/gestionbonificacion/update-table-column";
export const SET_PAGE = "fuerzaventas/gestionbonificacion/set-page";
export const SET_SELECTED_ROW =
    "fuerzaventas/gestionbonificacion/set-selected-row";
export const SET_ENTITY = "fuerzaventas/gestionbonificacion/set-entity";
export const RESTART_STATE = "fuerzaventas/gestionbonificacion/restart_state";
export const UPDATE_FIELD_ENTITY =
    "fuerzaventas/gestionbonificacion/update-field-entity";
export const SET_ERROR = "fuerzaventas/gestionbonificacion/set-error";
export const CLEAN_DATA2 = "fuerzaventas/gestionbonificacion/clean-data2";
