import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

const ReporteSeguimientoVendedorResumen = () => {

    // const getDataByLob = (data) => {
    //   console.log(data)
    //     if (data.length === 0) return;
    //     let sumarizado =
    //     _(data)
    //     .groupBy('vendedor')
    //     .map((item, key) => ({
    //         'category': key,
    //         'proyectados': _.countBy(item, i => i.OlaChannelMapId === 0), //=== true
    //         'ejecutados': _.countBy(item, i => i.OlaChannelMapId === 1  ), // === true
    //         'ola': _.countBy(item, i => i.OlaChannelMapId )

    //     }))
    //     .value();

    //     let storeData = _.map(sumarizado, (item) => {
    //         return {
    //             category: item.category,
    //             ejecutado: item.ejecutados['true'] ?? 0,
    //             pendiente: item.ejecutados['false'] ?? 0,
    //             porcentaje : (item.ejecutados['true'] * 100 ) /  item.ejecutados['false']
    //         };
    //     });

    //     if (storeData[0].category === 'null'){
    //         storeData.shift();
    //     }

    //     return storeData
    // }

    //amarra al reducers

    const loading = useSelector(state => state.reporteseguimientovendedor.loading);
    const tore = useSelector(state => state.reporteseguimientovendedor.store);
    const q = useSelector(state => state.reporteseguimientovendedor.filters.olaChannelMapId);
   const dhola = useSelector(state => state.reporteseguimientovendedor.store.dhola);
   const xhola = useSelector(state => state.reporteseguimientovendedor.filters.nombre);
   
   const tableResumen = useSelector(state => state.reporteseguimientovendedor.store.resumen);

  if (q === 0 ){
    return (

        <div>
          </div>
    )
  }
   if (tore.data === undefined ) {return};
   if (tore.data.length === 0 ) {return};

const ComponentToPrint  = () => {
    return (
      <>
        <div>
          <table style={{ border: "none" }}>
            <thead>
              <tr>
                <th style={{width: '10%', height:'100%', border:'none'}}></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row" style={{ border: "none" }}>
                 <p style ={{marginLeft: "50px"}}>RESUMEN ASESOR</p> 
                </th>
              </tr>
            </tbody>
          </table>

          <table border= '0' id="table-to-xls">
            <tbody>
              <tr style={{height: '20px'}} >
                <th scope="row" rowSpan= '2' style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', textAlign: 'center'}}>
                ASESOR</th>
                <th scope="row" rowSpan= '2' style={{ border: '1px solid',  textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white'}}>
                PUNTOS EN CARTERA A</th>
                  <th colSpan="3" style={{textAlign: 'center',  backgroundColor:  '#E62E2D',  color: 'white'}} >OLA ANTERIOR   { q > 1 ? dhola : 'SIN OLA'}</th>
                  <th colSpan="3" style={{textAlign: 'center',  backgroundColor:  '#23469c', color: 'white' }} >OLA ACTUAL   { q > 1 ? xhola : ' ' /*aOlas[0].dOla*/}</th>
              </tr>
              <tr>

              <td style={{ border: '1px solid',  textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#E62E2D', color: 'white'}}>
                EJECUTADOS A</td>
                <td style={{ border: '1px solid', textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#E62E2D', color: 'white', width: '130px'}}>
              PENDIENTES A</td>
              <td style={{ border: '1px solid', textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#E62E2D', color: 'white', width: '130px'}}>
              EFECTIVIDAD</td>

                <td style={{ border: '1px solid',  textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white'}}>
                EJECUTADOS</td>
                <td style={{ border: '1px solid', textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', width: '130px'}}>
              PENDIENTES</td>
              <td style={{ border: '1px solid', textAlign: 'center', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', width: '130px'}}>
              EFECTIVIDAD</td>
              </tr>
                {renderBody() }
                {renderTotal(tableResumen) }
              <tr></tr>

            </tbody>
          </table>
      </div>
    </>
    );
}

  const resumen = (dta) => {
    let storeData =
    {
        puntosCartera: _.sumBy(dta, 'puntosCartera'),     
        conchanneL_An: _.sumBy(dta, 'conchanneL_An'),
        sinchanneL_An: _.sumBy(dta, 'sinchanneL_An'),
       conchannel: _.sumBy(dta, 'conchannel'),
       sinchannel: _.sumBy(dta, 'sinchannel'),
    };
    return storeData
}

    const renderBody = () => {
        return tableResumen && tableResumen.map((f, index) => {
            return (
                 <tr style={{ }} key={index}>

                    <td  style={{width: '380px', height:'100%', background:'#cad1d7',border: "none" }}>{f.vendedor}</td>
                    <td style={{ width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff'}}>{f.puntosCartera}</td>
                    <td style={{ width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff'}}>{f.conchanneL_An}</td>
                    <td style={{ width : '170px' , textAlign: 'center',fontSize: '12px',  backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff'}}>{f.sinchanneL_An}</td>
                    <td style={{fontSize: '12px' , textAlign: 'center', backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff' ,
                    color : parseInt((f.conchanneL_An * 100)/(f.conchanneL_An+f.sinchanneL_An)) >50 ? 'black': 'red'}}>
                    {   (f.conchanneL_An+f.sinchanneL_An) > 0  ? parseInt((f.conchanneL_An * 100)/(f.conchanneL_An+f.sinchanneL_An)) : '' }%</td>

                    <td style={{ width : '170px' , textAlign: 'center',fontSize: '12px',  backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff' }}>{f.conchannel}</td>
                    <td style={{fontSize: '12px', textAlign: 'center', backgroundColor: '#ffffff'  }}> {f.sinchannel}</td>
                    <td style={{fontSize: '12px' , textAlign: 'center', backgroundColor: index%2 === 0 ?  '#ffffff': '#ffffff' , 
                    color : parseInt((f.conchannel * 100)/(f.conchannel+f.sinchannel)) >50 ? 'black': 'red'}}> { parseInt((f.conchannel * 100)/(f.conchannel+f.sinchannel)) }%</td>
              </tr>
            )
        })
    }

    const renderTotal = (data) => {

        let x = resumen(data);
            return (
                 <tr  >

                    <td style={{width: '280px', height:'80%' , textAlign: 'right', fontSize: '14px', fontWeight: 'bold',border: "none"}}>Total</td>
                    <td style={{ width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold',border: "none"  }}>{x.puntosCartera}</td>
                    <td style={{ width : '170px' , textAlign: 'center',fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold',border: "none"  }}>{x.conchanneL_An}</td>
                    <td style={{fontSize: '12px', textAlign: 'center', backgroundColor:  '#d5dbe0', fontWeight: 'bold',border: "none"  }}> {x.sinchanneL_An}</td>
                    <td style={{fontSize: '12px' , textAlign: 'center', backgroundColor: '#d5dbe0', fontWeight: 'bold',border: "none"  }}>
                     {   (x.conchanneL_An+x.sinchanneL_An) > 0  ?  parseInt((x.conchanneL_An * 100)/(x.conchanneL_An+x.sinchanneL_An))  : ''}%</td>

                    <td style={{ width : '170px' , textAlign: 'center',fontSize: '12px',  backgroundColor:  '#cad1d7' , fontWeight: 'bold',border: "none" }}>{x.conchannel}</td>
                    <td style={{fontSize: '12px', textAlign: 'center', backgroundColor:  '#d5dbe0' , fontWeight: 'bold',border: "none" }}> {x.sinchannel}</td>
                    <td style={{fontSize: '12px' , textAlign: 'center', backgroundColor: '#d5dbe0' , fontWeight: 'bold',border: "none"  }}>
                     { parseInt((x.conchannel * 100)/(x.conchannel+x.sinchannel)) }%</td>
                </tr>
            )
    }

    return (
        <>
        <div   style={{display: tore.data.length > 0  && !loading ? 'block': 'none'}}>
        <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                                <ComponentToPrint  />
                    </Grid>
        </Grid>
        </div>
         <div className="App" style={{height: '0px'}}>
          <Button
              color="secondary"
              size="small"
              variant="outlined"
              startIcon={<Icon className="fas fa-file-excel"/>}
              style={{position: 'absolute',
                bottom: '0',
                display: 'flex',
                flexdirection: 'column',
                left : '0px'
                }}
          >
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="RptChannelResumenSeguimientoVendedor"
                sheet="resumen vendedor"
                buttonText="Exportar"
                />
          </Button>
         </div>
         </>
      )


};

export default  ReporteSeguimientoVendedorResumen;