import React, { useState } from 'react';
import { useSelector } from 'react-redux';


import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ReporteParticipacionLob from './ReporteParticipacionLob';
import ReporteParticipacionCanal from './ReporteParticipacionCanal';
import ReporteParticipacionTotal from './ReporteParticipacionTotal';
import ReporteParticipacionTotalCanal from './ReporteParticipacionTotalCanal';
import ReporteParticipacionResumen from './ReporteParticipacionResumen';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ReporteParticipacionPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const [value, setValue] = useState(1);
    const data = useSelector(state => state.reporteparticipacion.data)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-signal" />} label="Tamaño Mercado" style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-chart-bar" />} label="Participación LOB" style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-chart-line" />} label="Participación CANAL" style={{ textTransform: "none",  backgroundColor: value === 2 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Participación CANAL PIE" style={{ textTransform: "none",  backgroundColor: value === 3 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Resumen MARCAS" style={{ textTransform: "none",  backgroundColor: value === 4 ? "#C9C9C9" : "" }} />
                </Tabs>
                {value === 0 && data.length > 0 && (<ReporteParticipacionTotal />)}
                {value === 1 && data.length > 0 && (<ReporteParticipacionLob />)}
                {value === 2 && data.length > 0 && (<ReporteParticipacionCanal />)}
                {value === 3 && data.length > 0 && (<ReporteParticipacionTotalCanal />)}
            
                {value === 4 && data.length > 0 && (<ReporteParticipacionResumen />)}</div>
        </Paper>
    );
}

export default ReporteParticipacionPanel;