export const CLEAN_FORM = 'aplicacioncliente/clean-form';
export const DROP_APPLICATION = 'aplicacioncliente/drop-application';
export const DROP_DISTRIBUIDOR = 'aplicacioncliente/drop-distribuidor';
export const ERROR_USERS_LIST = 'aplicacioncliente/error-aplicacioncliente-list';
export const RECEIVE_LIST = 'aplicacioncliente/receive-aplicacioncliente-list';
export const REQUEST_USERS_LIST = 'aplicacioncliente/request-aplicacioncliente-list';
export const RESTART_ERRORS = 'aplicacioncliente/restart-errors';
export const RESTART_STATE = 'aplicacioncliente/restart-state';
export const SELECT_APPLICATION = 'aplicacioncliente/select-application';
export const SELECT_DISTRIBUIDOR = 'aplicacioncliente/select-distribuidor';
export const SET_ENTITY = 'aplicacioncliente/set-entity';
export const SET_ERROR = 'aplicacioncliente/set-error';
export const SET_MASK = 'aplicacioncliente/set-mask';
export const SET_ORDER = 'aplicacioncliente/set-order';
export const SET_PAGE = 'aplicacioncliente/set-page';
export const SET_PAGE_SIZE = 'aplicacioncliente/set-page-size';
export const SET_PASSWORD = 'aplicacioncliente/set-password';
export const SET_SELECTED_ROW = 'aplicacioncliente/set-selected-row';
export const SET_STATE_FORM = 'aplicacioncliente/set-state-form';
export const UPDATE_FIELD_ENTITY = 'aplicacioncliente/update-field-entity';
export const UPDATE_FIELD_FILTER = 'aplicacioncliente/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'aplicacioncliente/update-table-column';
export const ACTUALIZAR_SUB_MODAL = 'aplicacioncliente/actualizar-sub-modal';