import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  RECEIVE_OLAS_DET,
} from "./types";

import { exportToSpreadsheet, cortarArreglo, searchVendedor } from "../../../helpers/commons";

export const detallePedido = (item) => async (dispatch, getState) => {
  if (getState().reportepedidos.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Pedidos/` +
      `${item["PedidoId"]}`;

    let response = await axios.get(encodeURI(url));
    let datad = response;
    //console.log(datad);
    //console.log(datad.data.detalle);

    dispatch({ type: RECEIVE_OLAS_DET, payload: datad.data.detalle });
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      console.log(error);
      msgError = "Error al conectar con el servidor....";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onExport = () => async (dispatch, getState) => {
  if (getState().reportepedidos.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch({ type: SET_MASK, loading: true });

    let head = getState().reportepedidos.table.columns;
    let json = getState().reportepedidos.store.data;

    console.log("cabecera: ",head)
    console.log("cuerpo: ", json)
    //return 
    exportToSpreadsheet(cortarArreglo(head,json), "ReportePedidos");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().reportepedidos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().reportepedidos.store.loading) {
    return;
  }

    dispatch({ type: SET_MASK, loading: true });

  try {
    let url = `${ getState().main.resources.uriSvcSalesMobilityAPI }api/v1/reportes/reportepedidos?` +
      `distribuidorId=${getState().reportepedidos.filters.distribuidorId}` +
      `&desde=${format( getState().reportepedidos.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format( getState().reportepedidos.filters.hasta,"yyyy-MM-dd" )}` +
      `&vendedorId=${getState().reportepedidos.filters.vendedorId}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    //console.log(data.columns)

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    // if (data.data.length > 0) {
    //   data.columns[0]["type"] = "text";
    //   //data.columns[0]['label'] ='# Pedido';
    //   //data.columns[2]["label"] = data.columns[2]["label"];

    //   //data.columns[2]["label"] = "RUC";
    //   //data.columns[3]['label'] = 'Nombre del Cliente';
    //   data.columns[3]["width"] = 300;
    //   // data.data[3]['width'] = 300;
    //   data.columns[4]["width"] = 250;
    //   //data.columns[4]['label'] = 'Nombre del Vendedor';
    //   //data.data[4]['width'] = 250;
    //   data.columns[5]["width"] = 200;
    //   //data.data[5]['width'] = 200;
    //   data.columns[6]["width"] = 150;
     
    // }

   // let columnas = getState().reportepedidos.table.columns;

   const head = [

    { dataKey: 'Pedido', label: 'Codigo Pedido', width: 90 },
    { dataKey: 'CodigoOrigen' ,  label: 'Codigo Origen',   width: 90 },
    { dataKey: 'Fecha', label: 'Fecha Registro', width: 100 },
    { dataKey: 'NIT/RUC', label: 'NIT/RUC', width: 100 },
    { dataKey: 'Cliente', label: 'Cliente', width: 350 },
    { dataKey: 'Vendedor', label: 'Vendedor', width: 350 },
    // { dataKey: 'Provincia', label: 'Provincia', width: 250 },
    { dataKey: 'Observación', label: 'Observación', width: 250 },
    // { dataKey: 'Estado', label: 'Estado', width: 90 },
    { dataKey: 'Enviado', label: 'Enviado', width: 90 },
    // { dataKey: 'Cotización', label: 'Cotización', width: 200 },
    { dataKey: 'Tipo', label: 'Tipo', width: 90 },
    { dataKey: "EstadoCartera", label: "Estado Cartera", width: 100},
    { dataKey: "DesdeCartera", label: "Desde Cartera", width: 120},
    { dataKey: "UsuarioCartera", label: "Usuario Cartera", width: 100},
    { dataKey: "EstadoDescuento", label: "Estado Descuento", width: 100},
    { dataKey: "DesdeDescuento", label: "Desde Descuento", width: 100},
    { dataKey: "UsuarioDescuento", label: "Usuario Descuento", width: 100},

   ]

    switch (getState().reportepedidos.filters.estado ) {
      case   "T" :
              dispatch({type: RECEIVE_OLAS_LIST,  columns: head,  payload: data.data });
               break;
      //  case    "C" :
      //         var y = data.data.filter( (e) => e.Cotizacion  ===  "Si");
      //         dispatch({ type: RECEIVE_OLAS_LIST, columns: head, payload: y });
      //         break;
      default:
     
        var x = data.data.filter( (e) => e.EstadoCartera === getState().reportepedidos.filters.estado && e.EstadoDescuento === getState().reportepedidos.filters.estado );
        dispatch({ type: RECEIVE_OLAS_LIST, columns: head, payload: x });
    }
  } catch (error) {
    let msgError = "";
    console.log(error);
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().reportepedidos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [{ dataKey: "nombre", label: "Nombre", type: "string", search: true, width: 225 },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 }];
  
    if (getState().reportepedidos.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
        + `?distribuidorId=${getState().reportepedidos.filters.distribuidorId}`               
        let response = await axios.get(encodeURI(url), { params: params });
        
        return searchVendedor(response, params.nombre);
    },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId)); //    usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_OLAS_LIST, columns: [], payload: [] });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
