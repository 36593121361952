import {
  FILTER_MAP_VENDEDOR,
  HIDE_POPUP_CLIENT,
  RECEIVE_DATA,
  RECEIVE_SUB_DETALLE,
  SET_MASK,
  SET_ORDER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SELECTED_ROW,
  SHOW_POPUP_CLIENT,
  UPDATE_FIELD_FILTER,
  UPDATE_TABLE_COLUMN,
  SET_STATE_FORM,
  RESTART_STATE,
  UPDATE_FIELD_ENTITYS
} from "./types";

const initialState = {
  formState: "search",
  loading: false,
  data: [],
  dVendedor: "",
  openPopup: false,
  entity: {
    desactivacionId: 0,
    codigoSucursal: "",
    comentario: "",
    estado: "P",
    dCliente: "",
    dDistribuidor: "",
    dEstado: "",
    dMotivoDesactivacion: "",
    fecha: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
    foto: "",
    latitud: 0.0,
    longitud: 0.0,
    motivoRechazo: "",
    sucursal: "",
    sucursalLatitud: 0.0,
    sucursalLongitud: 0.0,
    usuarioRegistro: "",
    telefono:"",
    telefono2:"",
    telefono3:"",
    correo:"",
    tipocliente:"",
    categoria:""
  },
  filters: {
    distribuidorId: 0,
    supervisorId: "",
    ubigeoId: 0,
    vendedorId: "",
    desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    hasta: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
    estado: 'P',
    dDistribuidor: "",
    dSupervisor: "",
    dVendedor: "",
    dUbigeo: "",
    todos: true,
    sw: true
  },
  store: {
    data: [],
    selected: [],
    detalle: [],
    subdetalle: []
  },
  table: {
    order: "asc",
    orderBy: "",
    columns: [
      // { dataKey: "Nombre", label: "Vendedor", width: 200 },
      // { dataKey: "ProspectoId", label: "ClienteId", width: 90 },
      // { dataKey: "NIT", label: "NIT/RUC", width: 100 },
      // { dataKey: "NombreComercial", label: "Nombre Comercial", width: 250 },
      // { dataKey: "DireccionFiscal", label: "Direccion Fiscal", width: 350 },
      // { dataKey: "NombreSucursal", label: "Nombre Sucursal", width: 250 },
      // { dataKey: "DireccionSucursal", label: "Direccion Sucursal", width: 350 },
      // { dataKey: "FechaRegistro", label: "Fecha Registro", width: 50 },
      // { dataKey: "Motivo", label: "Motivo", width: 250 },
      // { dataKey: "Correo", label: "Correo", width: 200 },
      // { dataKey: "Latitud", label: "Latitud", width: 200 },
      // { dataKey: "Longitud", label: "Longuitud", width: 200 },
      // { dataKey: "Estado", label: "Estado", width: 10 },
      // { dataKey: "Anulado", label: "Anulado", type: "boolean", width: 100 },
      { dataKey: "Nombre", label: "Vendedor", width: 200 },
      { dataKey: "NIT", label: "NIT/RUC", width: 100 },
      { dataKey: "NombreComercial", label: "Nombre Comercial", width: 250 },
      { dataKey: "DireccionFiscal", label: "Dirección Fiscal", width: 350 },
      { dataKey: "NombreSucursal", label: "Nombre Sucursal", width: 250 },
      { dataKey: "DireccionSucursal", label: "Direcioón Sucursal", width: 350 },      
      { dataKey: "FechaRegistro", label: "Fecha Registro", width: 100 },
      { dataKey: "Canal", label: "Canal", width: 60 },
      { dataKey: "Segmento", label: "Segmento", width: 130 },
      { dataKey: "Categoria", label: "Categoría", width: 150 },
      { dataKey: "Motivo", label: "Motivo", width: 250 },
      { dataKey: "Correo", label: "Correo", width: 200 },
      { dataKey: "Latitud", label: "Latitud", width: 200 },
      { dataKey: "Longitud", label: "Longuitud", width: 200 },
      { dataKey: "Estado", label: "Estado", width: 50 },
      { dataKey: "Contacto", label: "Contacto", width: 200 },
      { dataKey: "Anulado", label: "Anulado", type: "boolean", width: 80 },
      { dataKey: "ProspectoId", label: "ClienteId", width: 90 },
      { dataKey: "Comentario", label: "Comentario", width: 200 },
      { dataKey: "UriFoto01", label: "Foto01", width: 300 },
      { dataKey: "UriFoto02", label: "Foto02", width: 300 },
    ],
  },
};

export const aprobarprospectosReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case FILTER_MAP_VENDEDOR:
      return {
        ...state,
        dVendedor: action.dVendedor || "",
      };
    case HIDE_POPUP_CLIENT:
      return {
        ...state,
        openPopup: false,
      };
    case RECEIVE_DATA:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          data: action.payload,
          selected: [],
        },
        table: {
          ...state.table,
          columns: action.columns,
        },
      };
    case RECEIVE_SUB_DETALLE:
        return {
          ...state,
          store: {
            ...state.store,
            loading: false,
            subdetalle: action.payload,
            selected: [],
          }
        };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          order: action.order,
          orderBy: action.orderBy,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        store: {
          ...state.store,
          start: state.store.pageSize * action.page,
        },
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        store: {
          ...state.store,
          pageSize: action.pageSize,
        },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        store: {
          ...state.store,
          selected: action.selected,
        },
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case SHOW_POPUP_CLIENT:
      return {
        ...state,
        openPopup: true,
        storeClient: {
          ...state.store,
          data: action.storeData,
        },
      };
    case UPDATE_TABLE_COLUMN:
      return {
        ...state,
        table: {
          ...state.table,
          columns: action.columns,
        },
      };
    case SET_STATE_FORM:
      return {
        ...state,
        formState: action.formState,
      };
    case RESTART_STATE:
      return {
        ...initialState,
      };
      case UPDATE_FIELD_ENTITYS:
      return {
        ...state,
        entity:{
        ...state.entity,
        motivoRechazo: action.value,
        }
      };
    default:
      return state;
  }
};
