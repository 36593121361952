import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  actualiza,
  detalleCobranza,
  setOrder,
  onUpdateColumn,
  setSelectedRow,
  HandleShows,
  btnApprove,
} from "../../../store/movilidad/bandejaprobacion/actions";
import { Footer, Page } from '@sigeco/tools';
import Icon from '@material-ui/core/Icon';

import { sort_by} from '../../../helpers/commons'
import DetalleAprobarCobranza from './DetalleAprobarCobranza';

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const state = {
  eload: false,
  name: "",
  modalInputName: "",
  currentTab: 1,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  tableWrap: {
    flex: "1 1 auto",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    position: "absolute",
    maxHeight: 1100,
    maxWidth: 900,
    width: "80%",
    height: "90%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,0, 1, 2),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "max(1vw, 5px)",
    borderRadius: "15px",
  },
  bot: {
    marginLeft: "28px",
    marginTop:"5px",
  },
  boxcito: {
    overflow:"hidden",
    "@media (max-width: 599px)": {
      overflow: "auto",
      marginLeft: "-20px"
    },
  }
}));

const updateInputValue = (evt) => {
  state.modalInputName = evt.target.value;
};

function BandejAprobacionCobranzaList(props) {
  
  const classes = useStyles();
  const [modalInfo, setModalInfo] = useState([]);
  const [, setShowModal] = useState(false); //e
  const [show, setShow] = useState(false);  //e
  const HandleClose = () => setShow(false);
  const HandleShow = () => setShow(true);
  const [, setmotivoRechazo] = useState("");


  /*----------Estados que Abren los distitos Dialogs-------------------------*/
  const [ropen, setrOpen] = useState(false);
  const [tip, setTip] = useState("P");

  /*----------Manipulación de oredenamiento de la Table--------------------------*/
  const columns = useSelector((state) => state.bandejaaprobacion.tableAprobarCobranza.columns);
  const loading = useSelector((state) => state.bandejaaprobacion.loading);
  const order = useSelector((state) => state.bandejaaprobacion.tableAprobarCobranza.order);
  const orderBy = useSelector((state) => state.bandejaaprobacion.tableAprobarCobranza.orderBy);
  const store = useSelector((state) => state.bandejaaprobacion.store);
  const show2 = useSelector((state) => state.bandejaaprobacion.show);
  const dispatch = useDispatch();

  const [rechazo, ] = useState();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setOrder(isAsc ? "desc" : "asc", property,'tableAprobarCobranza'));
    let homes = store.dataAprobarCobranza;
    homes.sort(sort_by(property, isAsc, homes[0][property]));
  };
  useEffect(()=>{
    setShow(show2)
  },[show2])

  //---------Envia La Fila de la Tabla--
  const Clicke = (items) => {
    let item= []
    item.push(items)
    dispatch(detalleCobranza(item[0])); //mllama al detalle
    setModalInfo(item[0]);
    setShowModal(HandleShow);
    
    dispatch(setSelectedRow(item,'selectedAprobarCobranza'));
    dispatch(HandleShows(true));
  };

  //--------Elasticidad de las columnas--
  const handleResizeColumn = (column) => {
    dispatch(onUpdateColumn(column));
  };

  const handleDialogOpen = (tipo) => () => {
    //abre dialogo
    setTip(tipo);
    if (tipo === "R") {
      state.modalInputName = "";
      setmotivoRechazo("");
      setrOpen(true);
    }
  };

  //--------Cierre de los Dialogs
  const handleClose = (tipo) => () => {

          if (tipo === 4) {
      setrOpen(false);
    }
  };
 
  //-----Funcion que Despacha la acción y función estado que controla todo
  const handleSave = () => {
    if (state.modalInputName === "") {
      return;
    }
    setmotivoRechazo(state.modalInputName);

    dispatch(actualiza(modalInfo.cobranzaId, tip, state.modalInputName,'COB')); //cambia estado
    setrOpen(false);
    HandleClose();
  };

  const BottonDetalle = () => {
  
   return (
    <Page >
      {modalInfo.estado === "P" ? (
        // <div className="container"   style= {{ position: 'relative', float: 'right',  liststyletype: 'none',  right: '-40px', display:'inline-block'}}>
          <Footer >
          <Button
             variant="contained"
            onClick={() =>{dispatch(btnApprove())}}
            className={classes.bot}
            color="primary"
            startIcon={<Icon className ="fa fa-check"/>}
            size="small"
          >
            Aprobar
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            onClick={handleDialogOpen("R")}
            className={classes.bot}
            color="primary"
            startIcon={<Icon className ="fas fa-ban"/>}
            size="small"
          >
            Rechazar
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            onClick={()=>dispatch(HandleShows(false))}
            className={classes.bot}
            color="primary"
            startIcon={<Icon className="fas fa-door-open"/>}
            size="small"
          >
            Cerrar
          </Button>
          </Footer>

      ):(
        // <div className="container"   style= {{ position: 'relative', float: 'right', liststyletype: 'none',  right: '-40px', display:'inline-block'}}>
          <Footer>
          
          <Button
            variant="contained"
            color="primary"
            onClick={()=>dispatch(HandleShows(false))}
            className={classes.bot}
            startIcon={<Icon className="fas fa-door-open"/>}
            size="small"
          >
            Cerrar
          </Button>
          </Footer>
      )}
    </Page>
  );
  
  }; /* boton */

  const ModalContent = () => {
    const datad = useSelector((state) => state.bandejaaprobacion.store.detalleaprobarcobranza);
    return (
    <div >
       <Modal
          fullscreen={"lg"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={show}
          onClose={HandleClose}
        >

        <div  style={{diplay: 'flex', flex: '1', maxHeight:'390px' }}>
          <div  className={classes.modal} style={{ height:( datad.length === 1 || datad.length === 2 || datad.length === 3) ? '310px': (datad.length === 3 || datad.length === 4) ? '340px':'360px'}}>
          <Box sx={{ margin: 1,  height:'96%'}} className={classes.boxcito}>

              <DetalleAprobarCobranza /> 
              <footer  style={{
                         backgroundcolor: 'red',
                         position: 'absolute',
                         bottom: 5,
                         width: '100%',
                         height: '40px',
                         color: 'white'  }}  >
                <div className="container"   style= {{ position: 'relative', float: 'right',  liststyletype: 'none',  right: '3%', display:'inline-block'}}>
                 <BottonDetalle/> 
                 <div style={{minHeight: '20px',height: "20px"}}></div>
                </div>
              </footer>
          </Box>
              
          </div>
        </div>

      </Modal>
    </div>
    );
  }; /* modal content */

  const Rechazo = () => {
    // ESTE ES EL MODAL DE RECHAZO DE PEDIDO
    return (
      <Dialog
        open={ropen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo rechazo"
            multiline
            fullWidth
            value={rechazo}
            onChange={(evt) => updateInputValue(evt)}
            inputProps={{
              maxLength: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose(4)}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => handleSave(e)}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }; /* dialog Rechazo */

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div style={{ display: "none" }}>
        <ReactHTMLTableToExcel
          id="table-xls-button-rpt-pedidos"
          className=""
          table="table-to-xls"
          filename="Rpt_pedidos"
          sheet="Pedidos"
          buttonText=""
        />
      </div>

      <div className={classes.tableWrap}>
        <Table
          columns={columns}
          count={store.total}
          loading={loading}
          onRowClick={(items) => {}}
          onRowDoubleClick={(items) => Clicke(items)}
          onSort={(datakey) => handleRequestSort(datakey)}
          onUpdateColumn={handleResizeColumn}
          rowCount={store.dataAprobarCobranza.length}
          rowGetter={(index) => store.dataAprobarCobranza[index]}
          selected={store.selectedAprobarCobranza}
          sortBy={orderBy}
          sortDirection={order}
          noRowsRenderer={() => <div></div>}
        />
      </div>
      <div className="App">{show ? <ModalContent /> : null}</div>
      <div>{<Rechazo />}</div>
    </FieldSet>
  );
}

export default BandejAprobacionCobranzaList;
