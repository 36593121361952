import axios from "axios";
import _ from "lodash";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ACTUALIZAR_MODAL,
  ADD_VENDEDOR,
  RECEIVE_DATA,
  RECEIVE_DATA2,
  RESTART_STATE,
  SET_MASK,
  UPDATE_FIELD_FILTER,
  UPDATE_OLA_FIELD_FILTER,
  UPDATE_DIST_FIELD_FILTER,
  ACTUALIZAR_SUB_MODAL
} from "./types";

import { amarca,  alob, aCategoria } from "../../../helpers/commons";

export const btnSearch = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_MASK, loading: true });

    if (getState().reporteconstruccion.filters.olaId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar una Ola..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

      if (getState().reporteconstruccion.filters.dMarca === " ") {
      dispatch(onUpdateFieldFilter("dMarca", ""));
    }
    if (getState().reporteconstruccion.filters.dLob === "") {
      dispatch(onUpdateFieldFilter("dLob", ""));
    }

    if (getState().reporteconstruccion.filters.dCanal === "") {
      dispatch(onUpdateFieldFilter("dCanal", ""));
    }

    console.log(getState().reporteconstruccion.filters.vendedor)
    let obj = getState().reporteconstruccion.filters.vendedor === ""
        ? ""
        : JSON.stringify(getState().reporteconstruccion.filters.vendedor);

    let url =`${
        getState().main.resources.uriSvcSalesMobilityAPI }api/v1/reportes/channelmap/construccion?` +
      `olaChannelMapId=${getState().reporteconstruccion.filters.olaId}` +
      `&distribuidorId=${getState().reporteconstruccion.filters.distribuidorId}` +
      `&nit=${getState().reporteconstruccion.filters.nit}` +
      `&vendedor=${obj}` +
      `&departamento=${getState().reporteconstruccion.filters.dDepartamento}` +
      `&municipio=${getState().reporteconstruccion.filters.dMunicipio}` +
      `&marca=${getState().reporteconstruccion.filters.dMarca=== 'TODOS'?'':getState().reporteconstruccion.filters.dMarca}` +
      `&lob=${getState().reporteconstruccion.filters.dLob === 'TODOS'?'':getState().reporteconstruccion.filters.dLob}` +
      `&canal=${getState().reporteconstruccion.filters.dCanal}`;
    let response = await axios.get(encodeURI(url));
    
    let data = response.data.resumen;

    let data1 = response.data.detalle;


    // if (data.length === 0) {
    //   dispatch(
    //     openMessageBox({
    //       button: "ok",
    //       icon: "warning",
    //       message: "No existe Registro  para esta consulta",
    //       callback: () => dispatch(closeMessageBox()),
    //     })
    //   );
    //   return;
    // }
    dispatch({ type: RECEIVE_DATA, payload: data });
    dispatch({ type: RECEIVE_DATA2, payload: data1 });

    getState().reporteconstruccion.summary = {
      cuentaPDV: _.sumBy(data, "cuentaPDV"),
      mercadoPDV: _.sumBy(data, "mercadoPDV"),
      glnpdv: _.sumBy(data, "galones"),
      glnpdvmercado: _.sumBy(data, "galonesMercado"),
    };
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    // dispatch({ type: ERROR_R_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para estos Filtros"
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message: msgError,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnExport = () => async (dispatch, getState) => {
  
    if (getState().reporteconstruccion.data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No hay información para exportar.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  try {
    dispatch({ type: SET_MASK, loading: true });

    let button = document.getElementById("table-xls-button-rpt-indicadores");
    button.click();
  } catch (error) {
    console.log(error);
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const onSearchOlaChannelMapClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Ola", type: "string", width: 250 },
    { dataKey: "desde", label: "Dede", type: "string", width: 110 },
    { dataKey: "hasta", label: "Hasta", type: "string", width: 110 },
  ];

 // let data = aOlas;

  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Ola Channel Map",
        getData: async (params) => {
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/OlasChannelMap/olas`;
          let response = await axios.get(encodeURI(url), { params: params });
          response = {
            data: {
              start: 0,
              pageSize: 50,
              total: response.data.length,
              data: response.data,
            },
          };
          return response.data;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("olaId", result[0].olaChannelMapId));
            dispatch(onUpdateFieldFilter("dOla", result[0].nombre));
            dispatch(onUpdateOlaFieldFilter);
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateDistFieldFilter);
        }
      },
    })
  );
};

export const onSearchClienteClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nit", label: "Nit", type: "string", width: 125 },
  ];

  if (getState().reporteconstruccion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(onUpdateFieldFilter("nit", ""));
  dispatch(onUpdateFieldFilter("dCliente", ""));

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Cliente",
      getData: async (params) => {
        let input2 = JSON.stringify(params);
        let input = JSON.parse(input2);
        let url =
          `https://services.gazel.com/api/v1/Clientes/distribuidor?` +
          `&distribuidorId=${
            getState().reporteconstruccion.filters.distribuidorId
          }`;
        let response1 = await axios.get(encodeURI(url), { params: params });
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };

        if (input.nombre) {
          let final = response.data.data.filter((e) =>
            e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
          );
          response.data.data = final;
          response.data.total = final.length;
        }
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("nit", result[0].nit));
          dispatch(onUpdateFieldFilter("dCliente", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    //{ dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().reporteconstruccion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().reporteconstruccion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      multiSelect: true,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let input2 = JSON.stringify(params);
        let input = JSON.parse(input2);
        let url =
          `https://services.gazel.pe/api/v1/Vendedores/vendedoresola?` +
          `&olaId=${getState().reporteconstruccion.filters.olaId}` +
          `&distribuidorId=${
            getState().reporteconstruccion.filters.distribuidorId
          }`;
        let response1 = await axios.get(encodeURI(url), { params: params });
        console.log(response1);
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };

        if (input.nombre) {
          let final = response.data.data.filter((e) =>
            e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
          );
          response.data.data = final;
          response.data.total = final.length;
        }
        return response.data;
      },
      callback: (btn, result) => {
        console.log(result);
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch({
            type: ADD_VENDEDOR,
            payload: result.map((item) => {
              return {
                // documentoIdentidad: item.documentoIdentidad,
                nombre: item.nombre,
              };
            }),
          });
        }
      },
    })
  );
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "codigo", label: "Código", type: "string", width: 115 },
    {
      dataKey: "dUbigeo",
      label: "Ubigeo",
      type: "string",
      search: true,
      width: 225,
    },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: false,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de ubigeo",
      getData: async (params) => {
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/ubigeos?`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("ubigeoId", result[0].ubigeoId));
          dispatch(onUpdateFieldFilter("dUbigeo", `${result[0].dUbigeoId}`));
        }
      },
    })
  );
};
/* ------------------------------------------------------------------------------------------------------------------------------------*/
export const onSearchDepartaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];
  if (getState().reporteconstruccion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(onUpdateFieldFilter("departamentoId", ""));
  dispatch(onUpdateFieldFilter("dDepartamento", ""));
  dispatch(onUpdateFieldFilter("municipioId", ""));
  dispatch(onUpdateFieldFilter("dMunicipio", ""));
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Departamento",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/departamentos`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(url);

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };

        console.log(aux);
        console.log(response);
        console.log(params);
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          console.log(result);
          console.log(result[0]);
          dispatch(onUpdateFieldFilter("departamentoId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dDepartamento", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("municipioId", ""));
          dispatch(onUpdateFieldFilter("dMunicipio", ""));
        }
      },
    })
  );
};

export const onSearcMunicipioClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];
  if (getState().reporteconstruccion.filters.dDepartamento === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Departamento...",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Municipio",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/provincias?` +
          `codigodepartamento=${
            getState().reporteconstruccion.filters.departamentoId
          }`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(url);

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };

        console.log(aux);
        console.log(response);
        console.log(params);
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          console.log(result);
          console.log(result[0]);
          dispatch(onUpdateFieldFilter("municipioId", result[0].nombre)); //codigo
          dispatch(onUpdateFieldFilter("dMunicipio", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onSearchMarcaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dMarca", label: "Marca", type: "string", width: 250 },
  ];

  let data = amarca;

  if (getState().reporteconstruccion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección de Marca",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("marcaId", result[0].dMarca));
            dispatch(onUpdateFieldFilter("dMarca", result[0].dMarca));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchCanalClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Canal", type: "string", width: 250 },
  ];

  if (getState().reporteconstruccion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(onUpdateFieldFilter("canalId", ""));
  dispatch(onUpdateFieldFilter("dCanal", ""));
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Canal", //nal",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        /*
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/clientes/segmentos?` +
          //+ `distribuidorId${getState().reporteconstruccion.filters.distribuidorId}`
          `canalId= 1` +
          `&dcanal= 'B2C`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(url);
          */
        const aux = {
          data: aCategoria, //response.data,
          pageSize: 50,
          start: 0,
          total: 6, //response.data.length,
        };

        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          console.log(result);
          console.log(result[0]);

          dispatch(onUpdateFieldFilter("canalId", result[0].nombre)); //
          dispatch(onUpdateFieldFilter("dCanal", result[0].nombre));
        }
      },
    })
  );
};

export const onSearchLobClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dLob", label: "Nombre", type: "string", width: 250 },
  ];

  let data = alob;
  console.log(data);

  if (getState().reporteconstruccion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección de Lob",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            console.log(result[0]);
            dispatch(onUpdateFieldFilter("lobId", result[0].dLob));
            dispatch(onUpdateFieldFilter("dLob", result[0].dLob));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const restartState = () => ({ type: RESTART_STATE });

export const onUpdateOlaFieldFilter = (dispatch, getState) => {
  dispatch({ type: UPDATE_OLA_FIELD_FILTER });
};

export const onUpdateDistFieldFilter = (dispatch, getState) => {
  dispatch({ type: UPDATE_DIST_FIELD_FILTER });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_DATA, payload: [] });
};

export const actualizarModal = (detalle) => (dispatch, getState) =>{
  dispatch ({ type: ACTUALIZAR_MODAL, payload:detalle })
};

export const actualizaSubModal = (detalle) => (dispatch, getState) =>{
  dispatch ({ type: ACTUALIZAR_SUB_MODAL, payload:detalle })
};

