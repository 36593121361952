import {
    ADD_RUTAS_CLIENTE,
    CLEAN_FORM,
    DROP_RUTA_CLIENTE,
    ERROR_RUTAS_LIST,
    RECEIVE_RUTAS_LIST,
    REQUEST_RUTAS_LIST,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_RUTA_CLIENTE,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    dias: [
        { key: 'vLunes', label: 'Lunes' },
        { key: 'vMartes', label: 'Martes' },
        { key: 'vMiercoles', label: 'Miércoles' },
        { key: 'vJueves', label: 'Jueves' },
        { key: 'vViernes', label: 'Viernes' },
        { key: 'vSabado', label: 'Sábado' },
        { key: 'vDomingo', label: 'Domingo' }
    ],
    entity: {
        rutaId: 0,
        nombre: '',
        vLunes: false,
        vMartes: false,
        vMiercoles: false,
        vJueves: false,
        vViernes: false,
        vSabado: false,
        vDomingo: false,
        frecuencia: '',
        activo: true,
        rutaVendedorId: 0,
        vendedorId: 0,
        dVendedor: '',
        rutasCliente: [],
        todos: true,
        codigo: ''
    },
    errors: {
        nombre: '',
        vendedor: '',
        codigo: '',
        frecuencia: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        supervisorId: '',
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        frecuencia: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'nombre', label: 'Nombre Ruta', width: 296 },
            { dataKey: 'codigo', label: 'Codigo Ruta', width: 100 },
            { dataKey: 'vendedor', label: 'Nombre Vendedor', width: 296 },
            { dataKey: 'vLunes', label: 'Lunes', type: 'boolean', width: 45 },
            { dataKey: 'vMartes', label: 'Martes', type: 'boolean', width: 45 },
            { dataKey: 'vMiercoles', label: 'Miércoles', type: 'boolean', width: 45 },
            { dataKey: 'vJueves', label: 'Jueves', type: 'boolean', width: 45 },
            { dataKey: 'vViernes', label: 'Viernes', type: 'boolean', width: 45 },
            { dataKey: 'vSabado', label: 'Sábado', type: 'boolean', width: 45 },
            { dataKey: 'vDomingo', label: 'Domingo', type: 'boolean', width: 45 },
            { dataKey: 'dFrecuencia', label: 'Frecuencia', width: 110 },
            { dataKey: 'activo', label: 'Activo', type: 'boolean', width: 45},
            { dataKey: 'usuarioRegistro', label: 'Usuario de Registro', width: 110 },
            { dataKey: 'usuarioModificacion', label: 'Usuario de Modificación', width: 110 }
        ]
    }
};

export const rutasReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case ADD_RUTAS_CLIENTE:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    rutasCliente: [
                        ...state.entity.rutasCliente,
                        ...action.payload.map(item => {
                            if(state.entity.rutasCliente.filter(f => f.clienteSucursalId === item.clienteSucursalId).length === 0) {
                                return item;
                            }
                            else {
                                return null;
                            }
                        }).filter(f => f !== null)
                    ]
                }
            };
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case DROP_RUTA_CLIENTE:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    rutasCliente: [...state.entity.rutasCliente.filter(f => f.clienteSucursalId !== action.clienteSucursalId)]
                }
            };
        case ERROR_RUTAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    start: 0,
                    total: 0,
                    data: [],
                    source: null
                }
            };
        case RECEIVE_RUTAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };
        case REQUEST_RUTAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true,
                    source: action.source
                }
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    rutaId: action.payload.rutaId,
                    nombre: action.payload.nombre,
                    vLunes: action.payload.vLunes,
                    vMartes: action.payload.vMartes,
                    vMiercoles: action.payload.vMiercoles,
                    vJueves: action.payload.vJueves,
                    vViernes: action.payload.vViernes,
                    vSabado: action.payload.vSabado,
                    vDomingo: action.payload.vDomingo,
                    frecuencia: action.payload.frecuencia,
                    activo: action.payload.activo,
                    rutaVendedorId: action.payload.rutaVendedor.rutaVendedorId,
                    vendedorId: action.payload.rutaVendedor.vendedorId,
                    dVendedor: action.payload.rutaVendedor.codigo + ' - ' + action.payload.rutaVendedor.nombre,
                    rutasCliente: action.payload.rutasCliente,
                    codigo: action.payload.codigo
                }
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_PAGE:
            return {
                ...state,
                store: {
                    ...state.store,
                    start: state.store.pageSize*action.page
                }
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                store: {
                    ...state.store,
                    pageSize: action.pageSize
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_RUTA_CLIENTE:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    rutasCliente: state.entity.rutasCliente.map(item => {
                        if(item.clienteSucursalId === action.clienteSucursalId) {
                            return {
                                ...item,
                                [action.key]: action.value
                            }
                        }
                        else {
                            return item;
                        }
                    })
                }
            }
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}