import axios from "axios";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ADD_VENDEDOR,
  RECEIVE_DATA,
  RESTART_STATE,
  SET_MASK,
  UPDATE_FIELD_FILTER,
  UPDATE_OLA_FIELD_FILTER,
  UPDATE_DIST_FIELD_FILTER,
} from "./types";

import { amarca, alob, aCategoria } from "../../../helpers/commons";

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().reporteparticipacion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
      );
      return;
    }
    
    try {
      dispatch({ type: SET_MASK, loading: true });
      
      if (getState().reporteparticipacion.filters.dMarca === " ") {dispatch(onUpdateFieldFilter("dMarca", ""));}
      
      if (getState().reporteparticipacion.filters.dLob === "") {dispatch(onUpdateFieldFilter("dLob", ""));}
      
      if (getState().reporteparticipacion.filters.dCanal === "") {dispatch(onUpdateFieldFilter("dCanal", ""));}
     
    
    let obj = getState().reporteparticipacion.filters.vendedor === "" ? "": JSON.stringify(getState().reporteparticipacion.filters.vendedor)
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/channelmap/participacion?` +
      `olaChannelMapId=${getState().reporteparticipacion.filters.olaId}` +
      `&distribuidorId=${ getState().reporteparticipacion.filters.distribuidorId }` +
      `&nit=${getState().reporteparticipacion.filters.nit}` +
      `&vendedor=${obj}` +
      `&departamento=${getState().reporteparticipacion.filters.dDepartamento}` +
      `&municipio=${getState().reporteparticipacion.filters.dMunicipio}` +
      `&marca=${getState().reporteparticipacion.filters.dMarca === 'TODOS' ? '':getState().reporteparticipacion.filters.dMarca}` +
      `&lob=${getState().reporteparticipacion.filters.dLob === 'TODOS' ? '':getState().reporteparticipacion.filters.dLob}` +
      `&canal=${getState().reporteparticipacion.filters.dCanal}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    dispatch({ type: RECEIVE_DATA, payload: data });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onSearchOlaChannelMapClick = () => async (dispatch,getState) => {
  let fields = [
    { dataKey: "nombre", label: "Ola", type: "string", width: 250 },
    { dataKey: "desde", label: "Dede", type: "string", width: 110 },
    { dataKey: "hasta", label: "Hasta", type: "string", width: 110 },
  ];

 
  try {
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        multiSelect: true,
        paginated: true,
        remote: true,
        title: "Selección de Ola Channel Map",
        getData: async (params) => {
          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/OlasChannelMap/olas`;

          let response1 = await axios.get(encodeURI(url), { params: params });
          let response = {
            data: {
              start: 0,
              pageSize: 50,
              total: response1.data.length,
              data: response1.data,
            },
          };
          console.log(response1, 'vendedores')
          console.log(input)
          if (input.nombre) {
            let final = response.data.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
          return response.data;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("olaId", result[0].olaChannelMapId));
            dispatch(onUpdateFieldFilter("dOla", result[0].nombre));
            dispatch(onUpdateOlaFieldFilter);
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [ { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 }, ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch( onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`) );
          dispatch(onUpdateDistFieldFilter);
        }
      },
    })
  );
};

export const onSearchClienteClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nit", label: "Nit", type: "string", width: 125 },
  ];

  if (getState().reporteparticipacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Cliente",
      getData: async (params) => {
        let input2 = JSON.stringify(params);
        let input = JSON.parse(input2);
        let url =
          `https://services.gazel.pe/api/v1/Clientes/distribuidor?` +
          `&distribuidorId=${
            getState().reporteparticipacion.filters.distribuidorId
          }`;
        let response1 = await axios.get(encodeURI(url), { params: params });
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };

        if (input.nombre) {
          let final = response.data.data.filter((e) =>
            e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
          );
          response.data.data = final;
          response.data.total = final.length;
        }
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("nit", `${result[0].nit}`));
          dispatch(onUpdateFieldFilter("dCliente", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    {dataKey: "DocumentoIdentidad", label: "DocumentoIdentidad", type: "string", width: 115 },
  ];

  if (getState().reporteparticipacion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().reporteparticipacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  
  dispatch(openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      multiSelect: true,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let input2 = JSON.stringify(params);
        let input = JSON.parse(input2);
        let url = `https://services.gazel.pe/api/v1/Vendedores/vendedoresola?` +
        `&olaId=${getState().reporteparticipacion.filters.olaId}`+
        `&distribuidorId=${getState().reporteparticipacion.filters.distribuidorId}`;
       
        let response1 = await axios.get(encodeURI(url), { params: params });
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };
console.log(response1, 'vendedores')
        if (input.nombre) {
          let final = response.data.data.filter((e) =>
            e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
          );
          response.data.data = final;
          response.data.total = final.length;
        }
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          
            dispatch({ type: ADD_VENDEDOR, payload: result.map(item => {
            return {
                        documentoIdentidad: item.documentoIdentidad,
                        nombre: item.nombre  
            }
          })});
        }
      },
    })
  );
};
//borrar
export const onSearchUsuarioClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 110 },
    { dataKey: "nombre",label: "Nombres",search: true, type: "string", width: 175},
    { dataKey: "correo", label: "Correo", type: "string", width: 120 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios/porsupervisor?`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("usuarioId", result[0].usuarioId));
          dispatch(
            onUpdateFieldFilter(
              "dUsuario",
              `${result[0].nombreUsuario} - ${result[0].nombre}`
            )
          );
        }
      },
    })
  );
};
/* ------------------------------------------------------------------------------------------------------------------------------------*/
export const onSearchDepartaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];
  if (getState().reporteparticipacion.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Agente...",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }    

  dispatch(onUpdateFieldFilter("departamentoId", ""));
  dispatch(onUpdateFieldFilter("dDepartamento", ""));
  dispatch(onUpdateFieldFilter("municipioId", ""));
  dispatch(onUpdateFieldFilter("dMunicipio", ""));
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Departamento",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url = `${ getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/departamentos`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("departamentoId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dDepartamento", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("municipioId", ""));
          dispatch(onUpdateFieldFilter("dMunicipio", ""));
         
        }
      },
    })
  );
};

export const onSearcMunicipioClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  if (getState().reporteparticipacion.filters.dDepartamento === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Departamento...",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Municipio",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/provincias?` +
          `codigodepartamento=${getState().reporteparticipacion.filters.departamentoId }`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("municipioId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dMunicipio", `${result[0].nombre}`));
          }
      },
    })
  );
};

export const onSearchMarcaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dMarca", label: "Marca", type: "string", width: 250 },
  ];

  let data = amarca;
  if (getState().reporteparticipacion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {

    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección de Marca...",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("marcaId", result[0].marcaId));
            dispatch(onUpdateFieldFilter("dMarca", result[0].dMarca));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchLobClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dLob", label: "Nombre", type: "string", width: 250 },
  ];

  let data = alob;

  if (getState().reporteparticipacion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección de Lob",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("lobId", result[0].lobId));
            dispatch(onUpdateFieldFilter("dLob", result[0].dLob));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchCanalClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Canal", type: "string", width: 250 },
  ];
  if (getState().reporteparticipacion.filters.olaId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Ola..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(onUpdateFieldFilter("canalId", ""));
  dispatch(onUpdateFieldFilter("dCanal", ""));
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,


      paginated: true,
      remote: true,
      title: "Selección de Canal",
      getData: async (params) => {
        // let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/segmentos?` +
        //   `canalId= 1` +
        //   `&dcanal= B2C`;
        // let response = await axios.get(encodeURI(url), { params: params });

        // const aux = {
        //   data: response.data,
        //   pageSize: 50,
        //   start: 0,
        //   total: response.data.length,
        // };
        // return aux; //response.data;

        const aux = {
          data: aCategoria, //response.data,
          pageSize: 50,
          start: 0,
          total: 6, //response.data.length,
        };

        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("canalId", result[0].canalId));
          dispatch(onUpdateFieldFilter("dCanal", result[0].nombre));
        }
      },
    })
  );
};

/* ------------------------------------------------------------------------------------------------------------------------------------*/
//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });
export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_DATA, payload: [] });
};

export const restartState = () => ({ type: RESTART_STATE });

export const onUpdateOlaFieldFilter = (dispatch, getState) => {
  dispatch({ type: UPDATE_OLA_FIELD_FILTER });
};

export const onUpdateDistFieldFilter = (dispatch, getState) => {
  dispatch({ type: UPDATE_DIST_FIELD_FILTER });
};
