import {
    CLEAN_FORM,
    DROP_APPLICATION,
    DROP_DISTRIBUIDOR,
    ERROR_USERS_LIST,
    RECEIVE_USERS_LIST,
    RECEIVE_USERS_LIST2,
    REQUEST_USERS_LIST,
    RESTART_ERRORS,
    RESTART_STATE,
    SELECT_APPLICATION,
    SELECT_DISTRIBUIDOR,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_PASSWORD,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    ACTUALIZAR_SUB_MODAL
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    loadingStore: false,
    entity: {
        usuarioId: '',
        distribuidorId: null,
        jerarquiaId: null,
        nombre: '',
        nombreUsuario: '',
        clave: '',
        correo: '',
        rol: '',
        rolId: 0,
        codigoVendedor: '',
        documentoIdentidad: '',
        telefono: '',
        expiraClaveHabilitado: false,
        bloqueoHabilitado: false,
        activo: true,
        aplicaciones: [],
        distribuidores: [],
        dJerarquia: '',
        dDistribuidor: ''
    },
    errors: {
        nombre: '',
        nombreUsuario: '',
        correo: '',
        distribuidor: '',
        rol: ''
    },
    filters: {
        usuario: '',
        nombre: '',
        distribuidorId : 0,
        dDistribuidor:'',
        rol: '',
        rolId:''

    },
    store: {
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        data1: [],
        data2: [],
        selected: [],
        source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'nombre', label: 'Nombres', width: 275 },
            { dataKey: 'nombreUsuario', label: 'Usuario', width: 150 },
            { dataKey: 'rol', label: 'Rol', width: 150 },
            { dataKey: 'correo', label: 'Correo', width: 200 },
            { dataKey: 'telefono', label: 'Teléfono', width: 105 },
            { dataKey: 'activo', type: 'boolean', label: 'Activo', width: 90 }
        ]
    }
};

export const userReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case DROP_APPLICATION:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    aplicaciones: state.entity.aplicaciones.filter(f => f.aplicacionClienteId !== action.aplicacionClienteId)
                }
            };
        case DROP_DISTRIBUIDOR:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    distribuidores: state.entity.distribuidores.filter(f => f.distribuidorId !== action.distribuidorId)
                }
            };
        case ERROR_USERS_LIST:
            return {
                ...state,
                loadingStore: false,
                store: {
                    ...state.store,
                    start: 0,
                    total: 0,
                    data: [],
                    source: null
                }
            };
        case RECEIVE_USERS_LIST:
           // console.log(action.payload, 'ver esto aly data')

            return {
                ...state,
                loadingStore: false,
                store: {
                    ...state.store,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };
            case RECEIVE_USERS_LIST2:
               // console.log(action.payload, 'ver esto aly data1')

            return {
                ...state,
                loadingStore: false,
                store: {
                    ...state.store,
                    total: action.total,
                    data1: action.payload,
                    data2: action.payload,
                    source: null
                }
            };
        case REQUEST_USERS_LIST:
            return {
                ...state,
                loadingStore: true,
                store: {
                    ...state.store,
                    source: action.source
                }
            };
        case RESTART_ERRORS:
            return {
                ...state,
                errors: initialState.errors
            }
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SELECT_APPLICATION:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    aplicaciones: action.payload
                }
            };
        case SELECT_DISTRIBUIDOR:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    distribuidores: action.payload
                }
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    usuarioId: action.payload.usuarioId,
                    distribuidorId: action.payload.distribuidorId,
                    jerarquiaId: action.payload.jerarquiaId || '',
                    nombre: action.payload.nombre,
                    nombreUsuario: action.payload.nombreUsuario,
                    correo: action.payload.correo,
                    rol: action.payload.rol,
                    codigoVendedor: action.payload.codigoVendedor || '',
                    documentoIdentidad: action.payload.documentoIdentidad || '',
                    telefono: action.payload.telefono || '',
                    expiraClaveHabilitado: action.payload.expiraClaveHabilitado,
                    bloqueoHabilitado: action.payload.bloqueoHabilitado,
                    activo: action.payload.activo,
                    aplicaciones: action.payload.aplicaciones,
                    distribuidores: action.payload.distribuidores,
                    dJerarquia: action.payload.dJerarquia || '',
                    dDistribuidor: action.payload.dDistribuidor
                }
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            }
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_PAGE:
            return {
                ...state,
                store: {
                    ...state.store,
                    start: state.store.pageSize*action.page
                }
            }
        case SET_PAGE_SIZE:
            return {
                ...state,
                store: {
                    ...state.store,
                    pageSize: action.pageSize
                }
            };
        case SET_PASSWORD: {
            return {
                ...state,
                entity: {
                    ...state.entity,
                    clave: action.password
                }
            }
        }
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value + ''
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        case ACTUALIZAR_SUB_MODAL:
            return{
                ...state,
                store: {
                    ...state.store,
                    data1: action.payload,
                   
                }
            }
        default:
            return state;
    }

};