import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../config';
import { exportToSpreadsheet,cortarArreglo} from '../../helpers/commons';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../main/actions'
import {
    ADD_RUTAS_CLIENTE,
    CLEAN_FORM,
    DROP_RUTA_CLIENTE,
    ERROR_RUTAS_LIST,
    RECEIVE_RUTAS_LIST,
    REQUEST_RUTAS_LIST,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_RUTA_CLIENTE,
    UPDATE_TABLE_COLUMN
} from './types';

import { Arol} from "../../../src/helpers/commons" 

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnExport = () => async (dispatch, getState) => {
    

        if (getState().rutas.filters.distribuidorId   === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }

        if(getState().rutas.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
        
        try {

        dispatch({ type: SET_MASK, loading: true });

        // let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas/csv?`
        //     + `nombre=${getState().rutas.filters.nombre}`;
        // let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        // let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        // let link = document.createElement('a');

        // link.href = csvHref;
        // link.setAttribute('download', 'rutas.csv');
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        let head = getState().rutas.table.columns;
        var aux = [];

        for (var c in head){
            aux[c] = Object.values(head[c])[1];
        }

        var carArray = [];
  
        carArray.push(aux);
    
        let json = getState().rutas.store.data;

        var json1 = json.map(obj =>
            obj.activo === true ? { ...obj, activo: "Si" } : { ...obj, activo: "No" }
        );

        var json2 = json1.map(obj =>
            obj.vLunes === true ? { ...obj, vLunes: "Si" } : { ...obj, vLunes: "No" }
        );

        var json3 = json2.map(obj =>
            obj.vMartes === true ? { ...obj, vMartes: "Si" } : { ...obj, vMartes: "No" }
        );

        var json4 = json3.map(obj =>
            obj.vMiercoles === true ? { ...obj, vMiercoles: "Si" } : { ...obj, vMiercoles: "No" }
        );
        
        var json5 = json4.map(obj =>
            obj.vJueves === true ? { ...obj, vJueves: "Si" } : { ...obj, vJueves: "No" }
        );

        var json6 = json5.map(obj =>
            obj.vViernes === true ? { ...obj, vViernes: "Si" } : { ...obj, vViernes: "No" }
        );

        var json7 = json6.map(obj =>
            obj.vSabado === true ? { ...obj, vSabado: "Si" } : { ...obj, vSabado: "No" }
        );

        var json11 = json7.map(obj =>
            obj.vDomingo === true ? { ...obj, vDomingo: "Si" } : { ...obj, vDomingo: "No" }
        );


        // for (const object of json1) {
        
            
        //     if (object.activo === true) {
        //       object.activo = 'Si';
              
        //     }
        //     if (object.vLunes === true) {
        //         object.vLunes = 'Si';
                
        //     }
            
        //     else{
        //         if (object.activo === false) {
        //             object.activo = 'No';
        //             break;
        //           }
        //           if (object.vLunes === false) {
        //               object.vLunes = 'No';
        //           }
        //     }

        //   }
        //   var json11 = [...json1]

       
        for (var i in json11) {
            let ax = [];
            delete json11[i].canton
            delete json11[i].sector
            delete json11[i].provincia
            delete json11[i].rutaId
            let x = Object.values(json11[i]);
            ax[0] = x[0]
            ax[1] = x[10]
            ax[2] = x[1]
            ax[3] = x[2]
            ax[4] = x[3]
            ax[5] = x[4]
            ax[6] = x[5]
            ax[7] = x[6]
            ax[8] = x[7]
            ax[9] = x[8]
            ax[10] = x[11]
            ax[11] = x[9] 
            ax[12] = x[12]
            carArray.push(ax);
        }

        exportToSpreadsheet(carArray, "Rutas");

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
    }
};

export const btn2Export = () => async (dispatch, getState) => {
    


    if(getState().rutas.entity.rutasCliente.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {

    dispatch({ type: SET_MASK, loading: true });

    
    var columns =   [

        { dataKey: "vLunes", label: "Lunes", width: 200 },
        { dataKey: 'vMartes' ,  label: 'Martes',  type: 'boolean',  width: 80 },
        { dataKey: 'vMiercoles', label: 'Miercoles', width: 90 },
        { dataKey: "vJueves", label: "Jueves", width: 200 },
        { dataKey: "vViernes", label: "Viernes", width: 300 },
        { dataKey: "vSabado", label: "Sabado", width: 300 },
        { dataKey: "vDomingo", label: "Domingo", width: 200 },
        { dataKey: 'frecuencia', label: 'Frecuencia', width: 250 },
        { dataKey: 'dSucursal', label: 'Sucursal', width: 350 },
        { dataKey: 'codigoCliente' ,  label: 'Codigo Cliente',   width: 200 },
        { dataKey: 'codigoSucursal', label: 'Codigo Sucursal', width: 100 },
        { dataKey: 'dCliente', label: 'Cliente', width: 250 },
        { dataKey: 'latitud', label: 'Latitud', width: 200 },
        { dataKey: 'longitud', label: 'Longuitud', width: 200 },
        { dataKey: 'nit' ,  label: 'NIT',   width: 50},
        
        
    ]
    let head = columns;
    let json = getState().rutas.entity.rutasCliente;
   
    var json1 = json.map(obj =>
        obj.vLunes === true ? { ...obj, vLunes: "Si" } : { ...obj, vLunes: "No" }
    );

    var json2 = json1.map(obj =>
        obj.vMartes === true ? { ...obj, vMartes: "Si" } : { ...obj, vMartes: "No" }
    );

    var json3 = json2.map(obj =>
        obj.vMiercoles === true ? { ...obj, vMiercoles: "Si" } : { ...obj, vMiercoles: "No" }
    );
    
    var json4 = json3.map(obj =>
        obj.vJueves === true ? { ...obj, vJueves: "Si" } : { ...obj, vJueves: "No" }
    );

    var json5 = json4.map(obj =>
        obj.vViernes === true ? { ...obj, vViernes: "Si" } : { ...obj, vViernes: "No" }
    );

    var json6 = json5.map(obj =>
        obj.vSabado === true ? { ...obj, vSabado: "Si" } : { ...obj, vSabado: "No" }
    );

    var json7 = json6.map(obj =>
        obj.vDomingo === true ? { ...obj, vDomingo: "Si" } : { ...obj, vDomingo: "No" }
    );
    

    exportToSpreadsheet(cortarArreglo(head,json7), "Clientes de la Ruta " + getState().rutas.entity.nombre.toUpperCase());

} catch (error) {
    dispatch(openMessageBox({ 
        button: 'ok', 
        icon: 'error', 
        message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
        callback: () => dispatch(closeMessageBox())
    }));
} finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
}
};

export const btnSave = () => (dispatch, getState) => {

    if (getState().rutas.filters.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
      if (getState().rutas.entity.frecuencia === '' ) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar una Frecuencia..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
      let v = getState().rutas.store.data.filter(x => x.codigo ===   getState().rutas.entity.codigo  && 
      x.rutaId  !== getState().rutas.entity.rutaId);
        if (v.length > 0) {
        dispatch(
            openMessageBox({
            button: "ok",
            icon: "warning",
            message:  `Ya existe un codigo de ruta..! ${ getState().rutas.entity.codigo}`,
            callback: () => dispatch(closeMessageBox()),
            })
        );
        return;
        }

    if(getState().rutas.entity.nombre === '') {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' });
    }

    if(getState().rutas.entity.dVendedor === '') { //vendedorId
        dispatch({ type: SET_ERROR, key: 'vendedor', error: 'Este campo es requerido' });
    }

    if(getState().rutas.entity.frecuecia === '') { //vendedorId
        dispatch({ type: SET_ERROR, key: 'frecuencia', error: 'Este campo es requerido' });
    }

    if(getState().rutas.entity.codigo === '') { //vendedorId
        dispatch({ type: SET_ERROR, key: 'codigo', error: 'Este campo es requerido' });
    }

    if(getState().rutas.errors.nombre !== '' || getState().rutas.errors.vendedor !== '' || getState().rutas.errors.codigo  !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().rutas.entity.rutaId === 0) {
                dispatch(onSave(btn));
            }
            else {
                dispatch(onUpdate(btn));
            }

        }
    }));
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onRutasList());
};

export const onSearchRolClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Rol', 'search': true, 'type': 'string', 'width': 175 }
    ];

    let data = Arol; //'Planeación Colombia'

    dispatch(openAdvancedSearch({
        data: data,
        fields: fields,
        title: 'Selección de Rol',
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                if(getState().user.entity.rol !== result[0].nombre) {
                    dispatch(onUpdateFieldEntity('rol', result[0].nombre));

                }
                
            }
        }
    }));
};


export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let ruta = {
                ...getState().rutas.entity,
                distribuidorId: getState().rutas.filters.distribuidorId,
                codigo : getState().rutas.entity.codigo
            };

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas`;
            let response = await axios.post(url, ruta, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
             
            console.log('save ruta '+ ruta);
            console.log('save dta '+data);
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                 callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/fuerzaventas/rutas/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const    onUpdate = (btn) => async (dispatch, getState) => {
  
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let ruta = {
                ...getState().rutas.entity
            };

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Rutas`;
            let response = await axios.put(url, ruta, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
            
            console.log('update dta ---'+data);
            console.log(ruta)

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/fuerzaventas/rutas/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(getRuta(getState().rutas.filters.distribuidorId, data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/fuerzaventas/rutas`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getRuta = ( distribuidorId, rutaId) => async (dispatch, getState) => {
    if (rutaId === 0 )return;
    try {
        dispatch({ type: SET_MASK, loading: true });

        //let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas/${rutaId}`;
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas/listarrutas?`
        + `distribuidorId=${distribuidorId}`
        + `&rutaid=${rutaId}`;
        let response = await axios.get(encodeURI(url));
        console.log('get -------')
        console.log(response)
        if (!response.data.rutaVendedor || response.data.rutaVendedor === null || response.data.rutaVendedor === undefined  ){
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro de la Ruta Vendedor.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    // dispatch(push({
                    //     pathname: `/fuerzaventas/rutas`,
                    //     search: getState().router.location.search,
                    //     hash: getState().router.location.hash
                    // }));
    
                }
            }));
            return
        }

        let payload = {
            ...response.data
        };
        console.log(payload)
 
        
        dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
        console.log(error)

            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/fuerzaventas/rutas`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
       
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let rutaId = getState().rutas.entity.rutaId;
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas/${rutaId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se eliminó correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/fuerzaventas/rutas',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onDropRutaCliente = clienteSucursalId => ({ type: DROP_RUTA_CLIENTE, clienteSucursalId});

export const onRutasList = () => async (dispatch, getState) => {
    if(getState().rutas.store.source) {
        getState().rutas.store.source.cancel();
    }

    if (getState().rutas.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    let source = axios.CancelToken.source();
    let order = JSON.stringify([{
        property: getState().rutas.table.orderBy,
        direction: getState().rutas.table.order
    }]);
    dispatch({ type: REQUEST_RUTAS_LIST, source });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Rutas?`
            + `distribuidorId=${getState().rutas.filters.distribuidorId}`
            + `&supervisorId=${getState().rutas.filters.supervisorId}` 
            + `&nombre=${getState().rutas.filters.nombre}`
            + `&nombreComercial=${getState().rutas.filters.nombreComercial}`
            + `&sort=${order}&pageSize=${getState().rutas.store.pageSize}&start=${getState().rutas.store.start}`;
        let response = await axios.get(encodeURI(url), { cancelToken: source.token });
        let data = response.data;
        console.log('RUTAS  -------')
        console.log(response, 'RUTAS ')
        dispatch({ type: RECEIVE_RUTAS_LIST, total: data.total, payload: data.data });
        
        if (data.data.length  === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
    } catch (error) {
        console.log(error)
        dispatch({ type: ERROR_RUTAS_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchDiaRutaClick = () => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'label', 'label': 'Día', 'type': 'string', 'width': 300 }
    ];

    try {
        let data = [...getState().rutas.dias];

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            multiSelect: true,
            title: 'Días de SSemana',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    getState().rutas.dias.forEach(element => {
                        if(result.filter(f => f.key === element.key).length > 0) {
                            dispatch(onUpdateFieldEntity(element.key, true));
                        }
                        else {
                            dispatch(onUpdateFieldEntity(element.key, false));
                        }
                    });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchDiaRutaClienteClick = (clienteSucursalId) => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'label', 'label': 'Día', 'type': 'string', 'width': 300 }
    ];

    try {
        let data = [...getState().rutas.dias];

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            multiSelect: true,
            title: 'Días de Semana',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    getState().rutas.dias.forEach(element => {
                        if(result.filter(f => f.key === element.key).length > 0) {
                            dispatch(onUpdateRutaCliente(clienteSucursalId, element.key, true));
                        }
                        else {
                            dispatch(onUpdateRutaCliente(clienteSucursalId, element.key, false));
                        }
                    });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchSucursalClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dCliente', 'label': 'Cliente', 'type': 'string', 'search': true, 'width': 250 },
        { 'dataKey': 'codigoSucursal', 'label': 'Código Suc', 'type': 'string', 'width': 105 },
        { 'dataKey': 'dSucursal', 'label': 'Dirección', 'type': 'string', 'width': 300 },
        { 'dataKey': 'codigoCliente', 'label': 'Código', 'type': 'string', 'width': 105 },
        { 'dataKey': 'uso', 'label': 'Asignado', 'type': 'string', 'width': 50 },
        { 'dataKey': 'direccion', 'label': 'Dirección', 'type': 'string', 'width': 300 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        multiSelect: true,
        paginated: true,
        remote: true,
        title: 'Selección de cliente sucursal',
        getData: async (params) => {
            var url
            if (getState().rutas.entity.todos ){
                 url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/sucursales/sinruta?rutaId=${getState().rutas.entity.rutaId}`
                 + `&distribuidorId=${getState().rutas.filters.distribuidorId}`
                 + `&todos=0`;
            }else {
                 url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/sucursales/sinruta?rutaId=${getState().rutas.entity.rutaId}`
                 + `&distribuidorId=${getState().rutas.filters.distribuidorId}`
                 + `&todos=1`;
            }
             let response = await axios.get(encodeURI(url), { params: params });
             console.log(response.data)
            return response.data;
        },
        callback: (btn, result) => {
            console.log(result)
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') { //em
                let ruta = getState().rutas.entity;
                //console.log(ruta)

                dispatch({ type: ADD_RUTAS_CLIENTE, payload: result.map(item => {
                    return {
                        rutaClienteId: 0,
                        clienteSucursalId: item.sucursalId,
                        dSucursal: item.direccion,
                        latitud: item.latitud,
                        longitud: item.longitud,
                        codigoCliente: item.codigoCliente,
                        dCliente: item.dCliente,
                        vLunes: ruta.vLunes,
                        vMartes: ruta.vMartes,
                        vMiercoles: ruta.vMiercoles,
                        vJueves: ruta.vJueves,
                        vViernes: ruta.vViernes,
                        vSabado: ruta.vSabado,
                        vDomingo: ruta.vDomingo,
                        frecuecia: ruta.frecuencia, //em
                       // nit: item.nit, //em
                    };
                }) });
            }
        }
    }));
};

/* ------------------------------------------------------------------------------------------------------ */
export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
        let fields = [
          {
            dataKey: "nombre",
            label: "Nombre",
            type: "string",
            search: true,
            width: 225,
          },
          { dataKey: "codigo", label: "Codigo", type: "string", width: 115 },
        ];
      
        if (getState().rutas.filters.distribuidorId === 0) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "Debe seleccionar un Agente..",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
        }
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: "Selección de Vendedor",
            getData: async (params) => {
                //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;     
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
                + `?distribuidorId=${getState().rutas.filters.distribuidorId}`               
                let response = await axios.get(encodeURI(url), { params: params });
                const  aux = {
                    data: response.data  ,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length
                }
                return aux;  //response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldEntity('vendedorId', result[0].vendedorId));
                    dispatch(onUpdateFieldEntity('dVendedor', `${result[0].codigo} - ${result[0].nombre}`)); 
                    // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().aprobarpedido.table.columns, payload: [] });
                  }
 
            }
        }));
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'codigo', 'label': 'Código', 'type': 'string', 'width': 105 },
        { 'dataKey': 'nombre', 'label': 'Nombres', 'type': 'string', 'search': true, 'width': 275 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Vendedor',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/vendedores?soloActivos=true`
            let response = await axios.get(encodeURI(url), { params: params });
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('vendedorId', result[0].vendedorId));
                dispatch(onUpdateFieldEntity('dVendedor', `${result[0].codigo} - ${result[0].nombre}`));
            }
        }
    }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().rutas.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });
  
    if(key === 'nombre') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
    
    if(key === 'dVendedor') {
        dispatch({ type: SET_ERROR, key: 'vendedor', error: '' });
    }

    if(key === 'codigo') {
        dispatch({ type: SET_ERROR, key: 'codigo', error: '' });
    }

    if(key === 'frecuencia') {
        dispatch({ type: SET_ERROR, key: 'frecuencia', error: '' });
    }
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })

    dispatch({ type: RECEIVE_RUTAS_LIST, total: 0, payload: [] });
}

export const onUpdateRutaCliente = (clienteSucursalId, key, value) => ({ type: UPDATE_RUTA_CLIENTE, clienteSucursalId, key, value });

export const setFormState = (formState, id) => (dispatch, getState) => {
    let distribuidorId = getState().rutas.filters.distribuidorId;
    switch (formState) {
        case 'edit':
            if(id && parseInt(id) !== getState().rutas.entity.rutaId) {
                dispatch(getRuta( distribuidorId, id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().rutas.entity.rutaId) {
                dispatch(getRuta( distribuidorId, id));
            }
            break;
        default:
            break;
    }
    
    dispatch({ type: SET_STATE_FORM, formState });
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFieldFilter('nombre', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];
    if (getState().rutas.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor` // anterior /api/v1/usuarios?rol=EC Jefe zona`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().rutas.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }
            console.log(response.data)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFieldFilter('nombre', ''));
            }
        }
    }));
};