import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnExport,
    btn2Export,
    btnDelete,
    btnSave,
    btnSearch,
    setFormState
} from '../store/roles/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import RutasCliente from '../components/roles/RutasCliente';
import RutasFilter from '../components/roles/RutasFilter';
import RutasForm from '../components/roles/RutasForm';
import RutasList from '../components/roles/RutasList';

const Roles = ({onClose, setTitle}) => {
    const location = useLocation();
    const match = useRouteMatch();
    const formState = useSelector(state => state.rutas.formState);
    const loading = useSelector(state => state.rutas.loading);
    const rutaId = useSelector(state => state.rutas.entity.rutaId);
    const dispatch = useDispatch();

    useEffect(() => {
        switch(match.path) {
          case '/fuerzaventas/rutas/crear':
            setTitle('Crear');
            if(formState !== 'new') {
                dispatch(setFormState('new'));
            }
            break;
          case '/fuerzaventas/rutas/:id':
            dispatch(setFormState('view', match.params['id']));
            setTitle('Visualizar');
            break;
          case '/fuerzaventas/rutas/:id/editar':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar');
            break;
          default:
            dispatch(setFormState('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    function onBtnClose() {
        onClose();
    }

    return (
 
        <Page>
        <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
            </Backdrop>
            <Body >
                {formState === 'search' && <RutasFilter />}
                {formState === 'search' && <RutasList />}
                {formState !== 'search' && <RutasForm />}
                {formState !== 'search' && <RutasCliente />}
            </Body>
            <Footer>
                {formState === 'search' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar
                </Button>}
                {formState === 'search' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>}
                {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/fuerzaventas/rutas/crear', search: location.search, hash: location.hash }}>
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        startIcon={<Icon className="fas fa-file"/>}
                    >
                        Crear
                    </Button>
                </LinkContainer>}
                {formState === 'view' && 
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        onClick={() => dispatch(btn2Export())}
                        startIcon={<Icon className="fas fa-file-excel"/>}
                    >
                        Exportar
                    </Button>
                }
                {formState === 'view' && <LinkContainer to={{ pathname: `/fuerzaventas/rutas/${rutaId}/editar`, search: location.search, hash: location.hash }}>
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        startIcon={<Icon className="fas fa-edit"/>}
                    >
                        Modificar
                    </Button>
                </LinkContainer>}
                {formState === 'view' && <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => dispatch(btnDelete())}
                    startIcon={<Icon className="fas fa-trash"/>}
                >
                    Eliminar
                </Button>}
                {formState !== 'search' && formState !== 'view' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => dispatch(btnSave())}
                    startIcon={<Icon className="fas fa-save"/>}
                >
                    Guardar
                </Button>}
                {formState !== 'search' && <LinkContainer to={{ pathname: '/fuerzaventas/rutas', search: location.search, hash: location.hash }}>
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        startIcon={<Icon className="fas fa-times-circle"/>}
                    >
                        Cancelar
                    </Button>
                </LinkContainer>}
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
 
    )
};

export default Roles;