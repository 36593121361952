import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnSearch,
    onExport,
    onImport,
    restartState,
    setFormState,
    btnDeactivate,
    btnSave,
} from "../../store/fuerzaventas/gestionbonificacion/actions";

import { Body, Footer, Page } from "@sigeco/tools";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";

import GestionBonificacionFilter from "../../components/fuerzaventas/gestionbonificacion/GestionBonificacionFilter";
import GestionBonificacionPanel from "../../components/fuerzaventas/gestionbonificacion/GestionBonificacionPanel";
import GestionBonificacionForm from "../../components/fuerzaventas/gestionbonificacion/GestionBonificacionForm";

const GestionBonificacion = ({ onClose, setTitle }) => {
    const location = useLocation();
    const match = useRouteMatch();
    const formState = useSelector((state) => state.gestionbonificacion.formState);
    const loading = useSelector((state) => state.gestionbonificacion.loading);
    const store = useSelector((state) => state.gestionbonificacion.filters.band);
    const dtoId = useSelector((state) => state.gestionbonificacion.entity.bonificacionId);
    const dispatch = useDispatch();

    useEffect(() => {
        switch (match.path) {
            case "/fuerzaventas/gestionbonificacion/:id":
                dispatch(setFormState("view", match.params["id"]));
                setTitle("Visualizar Bonificacion");
                break;
            case "/fuerzaventas/gestionbonificacion/:id/editar":
                dispatch(setFormState("edit", match.params["id"]));
                setTitle("Editar Bonificacion");
                break;
            default:
                dispatch(setFormState("search"));
                setTitle("Búsqueda");
                break;
        }
    }, [dispatch, match.path, match.params, setTitle]);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop
                style={{ position: "absolute", zIndex: 1200 }}
                open={loading}
                transitionDuration={0}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                {formState === "search" && <GestionBonificacionFilter />}
                {formState === "search" && <GestionBonificacionPanel />}
                {formState !== "search" && <GestionBonificacionForm />}
            </Body>
            <Footer>
                {formState === "search" && store === true && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(onExport())}
                        startIcon={<Icon className="fas fa-file-excel" />}
                    >
                        Exportar
                    </Button>
                )}

                {formState === "search" && store === true && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(btnSearch())}
                        startIcon={<Icon className="fas fa-search" />}
                    >
                        Buscar
                    </Button>
                )}
                {formState === "search" && store === false && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(onImport())}
                        startIcon={<Icon className="fas fa-file-excel" />}
                    >
                        Importar Bonificaciones
                    </Button>
                )}
                {/* {formState === "view" && (
                    <LinkContainer
                        to={{
                            pathname: `/fuerzaventas/gestionbonificacion/${dtoId}/editar`,
                            search: location.search,
                            hash: location.hash,
                        }}
                    >
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            startIcon={<Icon className="fas fa-edit" />}
                        >
                            Modificar
                        </Button>
                    </LinkContainer>
                )} */}
                {formState === "view" && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(btnDeactivate())}
                        startIcon={<Icon className="fas fa-trash" />}
                    >
                        Desactivar
                    </Button>
                )}
                {formState !== "search" && formState !== "view" && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(btnSave())}
                        startIcon={<Icon className="fas fa-save" />}
                    >
                        Guardar
                    </Button>
                )}
                {formState !== "search" && (
                    <LinkContainer
                        to={{
                            pathname: "/fuerzaventas/gestionbonificacion",
                            search: location.search,
                            hash: location.hash,
                        }}
                    >
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            startIcon={<Icon className="fas fa-times-circle" />}
                        >
                            Cancelar
                        </Button>
                    </LinkContainer>
                )}
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onBtnClose()}
                    startIcon={<Icon className="fas fa-door-open" />}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    );
};

export default GestionBonificacion;
