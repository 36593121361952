import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnDelete,
    btnSave,
    btnSearch,
    onExport,
    restartState,
    setFormState
} from '../store/aplicacioncliente/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import AplicacionClienteList from '../components/aplicacioncliente/AplicacionClienteList';
import AplicacionClienteForm from '../components/aplicacioncliente/AplicacionClienteForm';
import AplicacionClienteModificar from '../components/aplicacioncliente/AplicacionClienteModificar';

const AplicacionCliente = ({onClose, setTitle}) => {
  const location = useLocation();
  const match = useRouteMatch();
  const formState = useSelector(state => state.aplicacioncliente.formState);
  const loading = useSelector(state => state.aplicacioncliente.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (match.path) {
        case '/aplicacioncliente/crear':
        setTitle('Crear Aplicación');
        if(formState !== 'new') {
            dispatch(setFormState('new'));
        }
        break;
        case '/aplicacioncliente/:id':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar Aplicación');
            break;
        case '/aplicacioncliente/:id/editar':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar Aplicación');
            break;
        default:
            dispatch(setFormState("search"));
            setTitle("Búsqueda");
            break;
        }
    }, [dispatch, match.path, match.params, setTitle]);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

 

  return (
    <Page>
      {/* <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Body>

        {formState === 'search' && <AplicacionClienteList />}
        {formState === 'new' &&  <AplicacionClienteForm />}
        {formState === 'edit' &&  <AplicacionClienteModificar />}
        
      </Body>
      <Footer>
       
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(onExport())}
          startIcon={<Icon className="fas fa-file-excel"/>}
        >
          Exportar
        </Button>}
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="outlined"
          onClick={() => dispatch(btnSearch())}
          startIcon={<Icon className="fas fa-search"/>}
        >
          Buscar
        </Button>}
        {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/aplicacioncliente/crear', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-file"/>}
          >
            Crear
          </Button>
        </LinkContainer>}
        {/* {formState === 'view' && <LinkContainer to={{ pathname: `/usuarios/${usuarioId}/editar`, search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-edit"/>}
          >
            Modificar
          </Button>
        </LinkContainer>} */}
        {formState === 'edit' && <Button 
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(btnDelete())}
          startIcon={<Icon className="fas fa-trash"/>}
        >
          Eliminar
        </Button>}

        {formState !== 'search' && formState !== 'view' && <Button
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(btnSave())}
          startIcon={<Icon className="fas fa-save"/>}
        >
          Guardar
        </Button>}

        {formState !== 'search' && <LinkContainer to={{ pathname: '/aplicacioncliente', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-times-circle"/>}
          >
            Cancelar
          </Button>
        </LinkContainer>}

        <Button 
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => onBtnClose()} 
          startIcon={<Icon className="fas fa-door-open"/>}
        >
          Salir
        </Button>
      </Footer>
    </Page>
  );
};

export default AplicacionCliente;