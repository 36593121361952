import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { baseclientesReducer } from './clientes/baseclientes/reducers';
import { basedatosReducer } from './channelmap/basedatos/reducers';
import { clientesReducer } from './clientes/clientes/reducers';
import { desactivacionReducer } from './channelmap/desactivacion/reducers';
import { mainReducer } from './main/reducers';
import { marcaReducer } from './channelmap/marca/reducers';
import { olasReducer } from './channelmap/olas/reducers';
import { profileReducer } from './profile/reducers';
import { reportecoberturaReducer } from './channelmap/reportecobertura/reducers';
import { reporteconstruccionReducer } from './channelmap/reporteconstruccion/reducers';
import { reporteparticipacionReducer } from './channelmap/reporteparticipacion/reducers';
import { reportepedidosReducer } from './movilidad/reportepedidos/reducers';
import { reporteseguimientoReducer } from './channelmap/reporteseguimiento/reducers';
import { reportevisitasReducer } from './movilidad/reportevisitas/reducers';
import { rutasReducer } from './fuerzaventas/rutas/reducers';
import { sessionReducer } from './session/reducers';
import { userReducer } from './user/reducers';
import { vendedorReducer } from './fuerzaventas/vendedor/reducers';
import { trackingReducer } from './movilidad/tracking/reducers'; //em 24-05-21
import { metasReducer } from './fuerzaventas/metas/reducers';
import { productosReducer } from './precios/productos/reducers';
import { reportefectividadReducer } from './channelmap/reportefectividad/reducers';
import { basedatosPreciosReducer } from './precios/basedatos/reducers';
import { aprobarpedidoReducer } from './movilidad/aprobarpedido/reducers';
import { resumenReducer } from './gigantes/resumen/reducers';
import { basedatosmarketingReducer } from './marketing/basemarketing/reducers';
import { avanceidentificacionmercadoReducer } from './marketing/avanceidentificacion/reducers';
import { reporteSeguimientoVendedorReducer } from './channelmap/reporteseguimientovendedor/reducers';
import { listarprospectosReducer} from './clientes/listarprospectos/reducers';
import { aprobarprospectosReducer} from './clientes/prospectos/reducers';
import { carteravendedorReducer} from './movilidad/carteravendedor/reducers';
import { listarproductosReducer } from './fuerzaventas/listarproductos/reducers';
import { ventaperdidaReducer } from './movilidad/ventaperdida/reducers';
import { reportefrecuenciavisitaReducer } from './movilidad/reportefrecuenciavisita/reducers';
import { preciostockReducer } from './fuerzaventas/preciostock/reducers';
import { reporteidentificacionmercadoReducer } from './marketing/reporteidentificacionmercado/reducers';
import { reportepromocionesReducer} from './marketing/reportepromociones/reducers';
import { reporteseguimientopreciosReducer} from './precios/reporteseguimientoprecios/reducers';
import { cargamasivaclientesReducer} from './clientes/cargamasivaclientes/reducers';
import {reportecobranzaReducer} from './movilidad/reportecobranza/reducers';
import {clientessinrutasReducer} from './clientes/clientessinrutas/reducers';
import { clientesvendedorReducer } from './clientes/clientesvendedor/reducers';
import { cargamasivarutasReducer} from './fuerzaventas/cargamasivarutas/reducers';
import {gestionrolesReducer} from './fuerzaventas/gestionroles/reducers';
import { bandejaaprobacionReducer } from './movilidad/bandejaprobacion/reducers';
import { planificarvisitaReducer } from './movilidad/planificarvisita/reducers';
import {reportedeincidenciasReducer} from './movilidad/reportedeincidencias/reducers';
import {costosproductosReducer} from './fuerzaventas/costosproductos/reducers';
import {gestionpreciosReducer} from './fuerzaventas/gestionprecios/reducers';
import {gestionpreciosespecialesReducer} from './fuerzaventas/gestionpreciosespeciales/reducers';
import { gestionbonificacionReducer } from "./fuerzaventas/gestionbonificacion/reducers";
import {reporteindicadoresReducer} from './precios/reporteindicadores/reducers';
import {gestionlistadepreciosReducer} from './fuerzaventas/gestionlistadeprecios/reducers';
import {asignarmarcadistribuidorReducer} from './channelmap/asignarmarcadistribuidor/reducers';
import {cargarmiespacioReducer} from './fuerzaventas/cargarmiespacio/reducers';
import {preciosReducer} from './precios/precios/reducers';
import {detallespedidoReducer} from './movilidad/detallespedido/reducers';

import {aplicacionclienteReducer} from './aplicacioncliente/reducers'

function createReducer(history) {
    return combineReducers({
        planificarvisita: planificarvisitaReducer,
        gestionprecios: gestionpreciosReducer,
        gestionlistadeprecios: gestionlistadepreciosReducer,
        cargamasivarutas: cargamasivarutasReducer,
        bandejaaprobacion: bandejaaprobacionReducer,
        baseclientes: baseclientesReducer,
        basedatos: basedatosReducer,
        desactivacion: desactivacionReducer,
        clientes: clientesReducer,
        main: mainReducer,
        marca: marcaReducer,
        metas: metasReducer,
        olas: olasReducer,
        profile: profileReducer,
        reportecobertura: reportecoberturaReducer,
        reporteconstruccion: reporteconstruccionReducer,
        reporteparticipacion: reporteparticipacionReducer,
        reportepedidos: reportepedidosReducer,
        reporteseguimiento: reporteseguimientoReducer,
        reportevisitas: reportevisitasReducer,
        router: connectRouter(history),
        rutas: rutasReducer,
        session: sessionReducer,
        user: userReducer,
        vendedor: vendedorReducer,
        tracking: trackingReducer,
        productos: productosReducer,
        reportefectividad: reportefectividadReducer,
        basedatosPrecios: basedatosPreciosReducer,
        aprobarpedido: aprobarpedidoReducer,
        resumen: resumenReducer,
        basedatosmarketing: basedatosmarketingReducer,
        avanceidentificacionmercado: avanceidentificacionmercadoReducer,
        reporteseguimientovendedor: reporteSeguimientoVendedorReducer,
        listarprospectos: listarprospectosReducer,
        aprobarprospectos: aprobarprospectosReducer,
        carteravendedor: carteravendedorReducer,
        listarproductos: listarproductosReducer,
        ventaperdida: ventaperdidaReducer,
        preciostock: preciostockReducer,
        reporteidentificacionmercado: reporteidentificacionmercadoReducer,
        reportepromociones: reportepromocionesReducer,
        reporteseguimientoprecios: reporteseguimientopreciosReducer,
        cargamasivaclientes: cargamasivaclientesReducer,
        reportecobranza: reportecobranzaReducer,
        clientessinrutas: clientessinrutasReducer,
        clientesvendedor: clientesvendedorReducer,
        gestionroles: gestionrolesReducer,
        reportefrecuenciavisita: reportefrecuenciavisitaReducer,
        reportedeincidencias: reportedeincidenciasReducer,
        gestionpreciosespeciales: gestionpreciosespecialesReducer,
        costosproductos: costosproductosReducer,
        gestionbonificacion: gestionbonificacionReducer,
        reporteindicadores : reporteindicadoresReducer,
        asignarmarcadistribuidor: asignarmarcadistribuidorReducer,
        cargarmiespacio : cargarmiespacioReducer,
        precios : preciosReducer,
        detallespedido : detallespedidoReducer,
        aplicacioncliente : aplicacionclienteReducer

    });
}

export default function configureStore(history, initialState) {
    const middleware = [thunk, routerMiddleware(history)];
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === "development";
    if (
        isDevelopment &&
        typeof window !== "undefined" &&
        window.devToolsExtension
    ) {
        enhancers.push(window.devToolsExtension());
    }

    const store = createStore(
        createReducer(history),
        initialState,
        compose(
            composeWithDevTools(applyMiddleware(...middleware)),
            ...enhancers
        )
    );

    return store;
}
