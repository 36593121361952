import axios from "axios"; //;
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
//import format from "date-fns/format";
//import { IdentityUrl } from '../../../config';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM,
  SET_STATE_FORM,
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
} from "./types";


export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});

export const setFormState = (formState, id) => (dispatch, getState) => {
  switch (formState) {
    case "edit":

      break;
    case "new":
      dispatch(cleanForm());
      break;
    case "search":
      dispatch(cleanForm());
      break;
    case "view":

      break;
    default:
      break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

//======================
export const onImport = () => async (dispatch, getState) => {

  dispatch({ type: SET_MASK, loading: true });
  
 
  if (getState().cargamasivaclientes.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  if (getState().cargamasivaclientes.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

      let aux = getState().cargamasivaclientes.store.data;
      console.log(aux);
      //var recordset = [];
      
      var recordset = {
        'clientes': []
      }
      for (var c in aux) {
           let json =
              {
                  "distribuidorId":  getState().cargamasivaclientes.filters.distribuidorId,
                  "codigo": aux[c].Codigo,
                  "nit": aux[c].NITRUC,
                  "nombreComercial": aux[c].NombreComercial             ,
                  "direccion": aux[c].Direccion             ,
                  "canal": aux[c].Canal,
                  "segmento": aux[c].Segmento             ,
                  "categoria": aux[c].Categoria             ,
                  "tipoCliente": aux[c].TipoCliente === undefined ? '' : aux[c].TipoCliente            ,
                  "categoria01": aux[c].categoria01 === undefined ? '' : aux[c].categoria01,
                  "categoria02": aux[c].categoria02 === undefined ? '' : aux[c].categoria02,
                  "credito": aux[c].Credito  === 'T' ? true : false,           
                  "lineaCredito": aux[c].LineaCredito,
                  "diasCredito": aux[c].DiasCredito             ,
                  "activo": true,
                  "sucursales": [
                    {
                      "codigoSucursal": aux[c].CodigoSucursal,
                      "direccion": aux[c].DireccionSucursal,
                      "codigoDepartamento": aux[c].CodigoDepartamento,
                      "codigoProvincia": aux[c].CodigoProvincia                 ,
                      "codigoDistrito": aux[c].CodigoDistrito,
                      "latitud": typeof (aux[c].Latitud) === 'string' ?  aux[c].Latitud : String(aux[c].Latitud )                ,
                      "longitud": typeof (aux[c].Longitud ) === 'string' ?  aux[c].Longitud  :  String(aux[c].Longitud ),
                      "codigoVendedor": typeof (aux[c].cedulaVendedor ) === 'string' ?  aux[c].cedulaVendedor  :  String(aux[c].cedulaVendedor ),
                      "codigoRuta": '',
                      "nombre": aux[c].NombreSucursal,
                      "activo": true
                    }
                  ]
              }
          //  ]
                 
          // }      
     
        //recordset.push(json);
        recordset.clientes.push(json)
     }

     console.log(recordset)
  try {
  
     let url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/crearclientemasivo` ;
     let response = await axios.post(url, recordset,  {headers: { "Content-Type": "application/json" }, });

     let data = response;
  
     if (data.status   === 201){
            dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'info', 
              message: 'Registro realizado con exito.', 
              callback: () => { 
                   dispatch({ type: SET_MASK, loading: false });
                 dispatch(closeMessageBox()); 
                 window.location.reload();
              }
          }));
       }


  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    //

        dispatch(push({
          pathname: `/clientes/cargamasivaclientes`,
          search: getState().router.location.search,
          hash: getState().router.location.hash
      }));

   dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
    return true
  }
};
//=====================

export const btnSearch = () => (dispatch) => {
  dispatch(setPage(0));
  dispatch(onList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
      
        }
      },
    })
  );
};

export const onList = () => async (dispatch, getState) => {
  if (getState().listarproductos.store.loading) {
    return;
  }

  if (getState().listarproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: SET_MASK, loading: true });
  //console.log(getState().vendedor);
  //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/costosproductoslistar?` +
      `distribuidorId=${getState().listarproductos.filters.distribuidorId}` +
      `&nombre=${getState().listarproductos.filters.nombre}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().listarproductos.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};


export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().listarproductos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
