import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateFieldEntity
} from '../../store/aplicacioncliente/actions';

import NativeSelect from "@material-ui/core/NativeSelect";
import Box from "@material-ui/core/Box";
import Checkbox from '@material-ui/core/Checkbox';
import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const AplicacionClienteModificar = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.aplicacioncliente.entity);
    const errors = useSelector(state => state.aplicacioncliente.errors);
    const formState = useSelector(state => state.aplicacioncliente.formState);
    const dispatch = useDispatch();
    const [, setEstado] = useState("");
    const isView = formState === 'view';
    
    function onFieldChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }

    function onChange(event) {
        setEstado(event.target.value);
        dispatch(onUpdateFieldEntity("tipoAplicacion", event.target.value));
    }

    const handleEstado = (e) => {
        setEstado(e);
    } ;

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Aplicación">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombre !== ''}
                                    fullWidth
                                    helperText={errors.nombre}
                                    label="Nombre de la Aplicación"
                                    name="nombre"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.nombre}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 120,
                                        readOnly: true                                      
                                        
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.redirigirUri !== ''}
                                    fullWidth
                                    helperText={errors.redirigirUri}
                                    label="URL de la nueva Aplicacion"
                                    name="redirigirUri"
                                    value={entity.redirigirUri}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    required
                                    inputProps={{
                                        maxLength: 120,
                                        readOnly: true
                                       
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                   // error={errors.tipoAplicacion !== ''}
                                    fullWidth
                                    helperText={errors.tipoAplicacion}
                                    label="URL de la nueva Aplicacion"
                                    name="redirigirUri"
                                    value={entity.tipoAplicacion}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    required
                                    inputProps={{
                                        maxLength: 120,
                                        readOnly: true
                                       
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                       
                    </Grid>
                </div>
            </FieldSet>
        </div>
    )
};

export default AplicacionClienteModificar;