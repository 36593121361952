import axios from "axios"; 
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../config';
import { generateRandomCode } from '../../helpers/commons';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../main/actions'
import {
    CLEAN_FORM,
    DROP_APPLICATION,
    DROP_DISTRIBUIDOR,
    ERROR_USERS_LIST,
    RECEIVE_LIST,
    REQUEST_USERS_LIST,
    RESTART_STATE,
    SELECT_APPLICATION,
    SELECT_DISTRIBUIDOR,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_PASSWORD,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    ACTUALIZAR_SUB_MODAL
} from './types';

import { exportToSpreadsheet, cortarArreglo, Arol } from "../../../src/helpers/commons";


export const cleanForm = () => ({ type: CLEAN_FORM });

export const getAplicacionCliente = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let aux = getState().aplicacioncliente.store.aux;
        let payload = {
            ...aux,
        };        

       
        if(payload.tipoAplicacion === 0)
        {
            payload.tipoAplicacion = 'Aplicación Movil'
        }
        else
        {
            payload.tipoAplicacion = 'Aplicación Web'
        }
        
        

        dispatch({ type: SET_ENTITY, payload });

    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/aplicacioncliente`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDropApplication  = (aplicacionClienteId) => ({ type: DROP_APPLICATION, aplicacionClienteId });

export const onDropDistribuidor  = (distribuidorId) => ({ type: DROP_DISTRIBUIDOR, distribuidorId });

export const onExport = () => async (dispatch, getState) => {
    try {

        if(getState().aplicacioncliente.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let head = getState().aplicacioncliente.table.columns;
        let json = getState().aplicacioncliente.store.data;


        exportToSpreadsheet(cortarArreglo(head,json) , "Aplicacion_Cliente");

  

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchApplicationClick = () => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'nombre', 'label': 'Aplicación', 'type': 'string', 'width': 300 }
    ];

    try {

        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            multiSelect: true,
            paginated: true,
            remote: true,
            multiSelect: true,
            title: 'Aplicaciones Cliente',
            getData: async (params) => {
                let url = `${IdentityUrl}/api/v1/usuarios/listAplicaciones`;
                let response = await axios.get(encodeURI(url), { params: params });
    
                const  aux = {
                    data: response.data  ,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length
                }
                return aux;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    let aplicaciones = getState().user.entity.aplicaciones.map(item => ({ ...item}));

                    result.forEach(element => {
                        if(aplicaciones.filter(f => f.aplicacionClienteId === element.aplicacionClienteId).length === 0) {
                            aplicaciones.push({ aplicacionClienteId: element.aplicacionClienteId, nombre: element.nombre });
                        }
                    });

                    dispatch({ type: SELECT_APPLICATION, payload: aplicaciones });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};



export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre' || key === 'redirigirUri') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value  });

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().aplicacioncliente.entity.aplicacionClienteId) {
                dispatch(getAplicacionCliente());
            }
            break;
        case 'new':
            
            dispatch(cleanForm());
            break;

        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().aplicacioncliente.entity.usuarioId) {
                dispatch(getAplicacionCliente());
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
}

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnSave = () => (dispatch, getState) => {
    if(getState().aplicacioncliente.entity.nombre === '') {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' })
    }

    if(getState().aplicacioncliente.entity.redirigirUri === '') {
        dispatch({ type: SET_ERROR, key: 'redirigirUri', error: 'Este campo es requerido' })
    }


    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            dispatch(onSave(btn));
        }
    }));
};

export const btnSearch = () => async (dispatch,getState) => {

    if (getState().aplicacioncliente.store.loading) {
        return;
    }

    dispatch({ type: SET_MASK, loading: true });

    let source = axios.CancelToken.source();
    dispatch({ type: REQUEST_USERS_LIST, source });
    
    try {
       
        let url = `${IdentityUrl}/api/v1/usuarios/listAplicaciones`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        //console.log(data)

        if (data.length === 0) {
            dispatch(
              openMessageBox({
                button: "ok",
                icon: "warning",
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox()),
              })
            );
            return;
        };

        let columnas = getState().aplicacioncliente.table.columns;


        dispatch({ type: RECEIVE_LIST, columns: columnas, payload: data });

    } catch (error) {
        dispatch({ type: ERROR_USERS_LIST });
        dispatch({ type: SET_MASK, loading: false });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let aplicacionId = getState().aplicacioncliente.entity.aplicacionClienteId;
            //console.log("ID :", aplicacionId);

            //return 0;
            let url = `${IdentityUrl}/api/v1/usuarios/deleteAplicacionCliente/${aplicacionId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/aplicacioncliente',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });


            let registro = {
            AplicacionClienteId: getState().aplicacioncliente.entity.nombre.replace(/\s/g, ''),
			Nombre: getState().aplicacioncliente.entity.nombre,
            TipoAplicacion: parseInt(getState().aplicacioncliente.entity.tipoAplicacion),
			RedirigirUri: getState().aplicacioncliente.entity.redirigirUri
            }

            // console.log(registro)
            //return 0 ;

            let url = `${IdentityUrl}/api/v1/usuarios/insertAplicacionCliente`;
            let response = await axios.post(url, registro, {headers: {'Content-Type': 'application/json'}});

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/aplicacioncliente`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });
            //console.log(getState().user.entity);
            let usuario = {
                ...getState().user.entity,
                aplicaciones: getState().user.entity.aplicaciones.map(item => {
                    return {
                        aplicacionClienteId: item.aplicacionClienteId
                    };
                }),
                distribuidores: getState().user.entity.distribuidores.map(item => {
                    return {
                        distribuidorId: item.distribuidorId
                    };
                })
            };

            if(getState().user.entity.clave !== '') {
                usuario.ReiniciarClave = true;
            }

            let url = `${IdentityUrl}/api/v1/usuarios`;
            let response = await axios.put(url, usuario, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
           // console.log(data,'que traes ')

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/aplicacioncliente`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(getAplicacionCliente(data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/aplicacioncliente`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().aplicacioncliente.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const actualizaSubModal = (detalle) => (dispatch, getState) =>{
    dispatch ({ type: ACTUALIZAR_SUB_MODAL, payload:detalle })
};

export const onRol = () => async (dispatch) => {
    let fields = [
        { dataKey: 'nombre', label: 'Rol', type: 'string', width: 175 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Rol",
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/listRol`;
            let response = await axios.get(encodeURI(url), { params: params });
            //console.log(response.data)
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('rol', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('rolId', `${result[0].rolId}`));
            }
        },
    }));
};